import { Template } from '../index'
import { MacroArea } from './macroarea'
import { Section } from './section'
import { ComponentGroup } from './componentGroup'
import { Component } from './component'
import { Metric } from './metric'
import { SectionGroup } from './sectiongroup'

export const Plant: Template = {
  id: 'plant',
  name: 'Plant',
  version: '0.1.1',
  date: '2019-02-21',
  fields: [
    {
      name: 'label',
      type: 'string',
      label: 'plant.label',
      width: 12,
      required: true,
      nodePath: 'data',
    },
    {
      name: 'id',
      type: 'string',
      label: 'plant.id',
      width: 4,
      required: true,
      nodePath: 'data',
    },
  ],
  copyable: false,
  allowedSubcomponents: [MacroArea, SectionGroup, Section, ComponentGroup, Component, Metric],
}
