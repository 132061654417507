import * as React from 'react'
import * as moment from 'moment'
import {formatBytes} from "@mv-submodules/inplant-designer-fe/functions/shared";
import IconComponent from '../../../../../inplant-components-fe/ui/components/MVIcon/Icon';

export interface StateProps {
  data: any
  selectedFile: any
}

export interface OwnProps {
  data: any
  selectedFile: any
  selectFile: (fileData: any) => Function
}

class File extends React.Component<OwnProps, StateProps> {
  constructor(props: OwnProps) {
    super(props)

    this.state = {
      data: null,
      selectedFile: null,
    }

    this.toggleFileSelect = this.toggleFileSelect.bind(this)
  }

  public render() {
    return (
      <React.Fragment>
        {this.props.data && (
          <li
            className={
              'list-file-single list-group-item ' + (this.props.data === this.props.selectedFile ? 'selected' : '')
            }
            key={this.props.data.ETag + this.props.data.Key}
            onClick={e => this.toggleFileSelect(e, this.props.data)}
          >
            <IconComponent icon={'file'} />
            <span className="file-name">
              {this.props.data.Key.substring(this.props.data.Key.lastIndexOf('/') + 1)} (
              {formatBytes(this.props.data.Size)})
            </span>
            <span className="file-data">{moment(this.props.data.LastModified).format('DD-MM-YYYY')}</span>
          </li>
        )}
      </React.Fragment>
    )
  }

  private toggleFileSelect(e: React.MouseEvent<HTMLLIElement>, data: any) {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    this.props.selectFile(data)
  }
}

export default File
