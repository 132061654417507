import './prototypes'
import * as templateInterpolationTemplates from '../types/templates/plc'

const templateInterpolations = templateInterpolationTemplates.default

const UnitsOfMeasurement = {
  ActivePower: 'KWh',
  AirFlowRate: 'm³/h',
  Binder: 'Kg',
  CarbonFootprint: 'CO₂/m²',
  CompressedAir: 'm³',
  ComprimedAir: 'm³',
  Current: 'A',
  DemiWater: 'm³',
  EndCycleTime: 'min',
  ExhaustChaminsTemp: '°C',
  ExhaustChaminsVol: 'Nm³',
  Gas: 'Nm³',
  Painted: 'M2',
  Paste: 'Kg',
  PauseTime: 'min',
  PL: 'Frame/Pieces loaded',
  Pressure: 'Bar',
  Product: 'L/h',
  ProductionTime: 'min',
  PS: 'Frame/Pieces unloaded',
  PU: 'Frame/Pieces scraped',
  RawWater: 'm³',
  Temperature: '°C',
  ThermalEnergy: 'kW/h',
  TPE: 'End Production',
  TPLMNT: 'Maintenance',
  TPLON: 'Plant On - all items work',
  TPLRDY: 'Plant Ready - Plant temperature reached',
  TPLUP: 'Plant Uptime',
  TPLWU: 'Plant Warm-up',
  TPP: 'Production Pause / Volountary Stop',
  TPROFF: 'Off Production (Energy Saving)',
  TPRON: 'On Production - Movement On',
  TPRNC: 'Production not compliant',
  TPS: 'Production Stop / Alarms Stop',
  TSON: 'Switch-On Plant',
  WarmupTime: 'min',
  WaterConsumption: 'm³',
  ChimneyGasTemperature: 'C',
  ChimneyGasVolume: 'm³',
  Conductivity: 'miS',
  DoughConsumption: 'Kg',
  ElectricCharge: '°C',
  ElectricConsumption: 'KWh',
  EmergencyTime: 'min',
  GasConsumption: 'm³',
  GasFlowRate: 'Nm3h',
  Level: 'M',
  NoProductionTime: 'min',
  Power: 'KWh',
  ResetHour: 'Bool',
  ResinConsumption: 'Kg',
  RestartI4pService: 'Restart i4Paintshop service command',
  StartupTime: 'min',
  Totalminutes: 'min',
}

const getUnitOfMeasurement = (metric: string) => {
  return UnitsOfMeasurement[metric] || ''
}

const MacroAreaIDs = {
  pretrattamento: 1,
  cataphoresis: 2,
  forni: 3,
  'sezione applicazione polveri': 4,
  'sistema di trasporto': 5,
  ausiliari: 6,
  loadunload: 7,
}

const stringInterpolations = {
  MacroArea: {
    pretrattamento: 'Treatment',
    cataforesi: 'Cataphoresis',
    forni: 'Ovens',
    'sezione applicazione polveri': 'Booths',
    'sistema di trasporto': 'Transport',
    ausiliari: 'Auxiliary',
    'carico scarico': 'LoadUnload',
  },
  Section: {
    Prelavaggio: 'Pretreatment: Struct // Tanks',
  },
  Component: {
    Ausiliari: 'Ausiliari',
    Cabina: 'PowderBoth',
    Elettroforesi: 'Elettroforesi${n}',
    'Forno Essicatoio': 'DryerOven',
    'Forno Polveri': 'PowderOven',
    Forno: 'CuringOven',
    PostCombustore: 'PostCombustore',
    'Sezione applicazione polveri': 'Sezione applicazione polveri',
    Vasca: 'Tank${n}',
    'UF Module': 'UF Module${n}',
  },
  ComponentType: {
    i4P_BlastingMachine: 'BlastingMachine${n} : "i4P_BlastingMachine"; // ${pid} - ${label}',
    i4P_Boiler: 'Boiler${n} : "i4P_Boiler"; // ${pid} - ${label}',
    i4P_Burner: 'Burner${n} : "i4P_Burner"; // ${pid} - ${label}',
    i4P_Chiller: 'Chiller${n} : "i4P_Chiller"; // ${pid} - ${label}',
    i4P_CompressedAirMeter: 'CompressedAirMeter${n} : "i4P_CompressedAirMeter"; // ${pid} - ${label}',
    i4P_ConductivityMeter: 'ConductivityMeter${n} : "i4P_ConductivityMeter"; // ${pid} - ${label}',
    i4P_Consumption: 'Consumption${n} : "i4P_Consumption"; // ${pid} - ${label}',
    i4P_DosingPump: 'DosingPump${n} : "i4P_DosingPump"; // ${pid} - ${label}',
    i4P_ElectricItem: 'ElectricItem${n} : "i4P_ElectricItem"; // ${pid} - ${label}',
    i4P_ElectricItemFan: 'Fan${n} : "i4P_ElectricItem"; // ${pid} - ${label}',
    i4P_ElectricItemMotor: 'Motor${n} : "i4P_ElectricItem"; // ${pid} - ${label}',
    i4P_ElectricItemPump: 'Pump${n} : "i4P_ElectricItem"; // ${pid} - ${label}',
    i4P_FlowMeter: 'FlowMeter${n} : "i4P_FlowMeter"; // ${pid} - ${label}',
    i4P_LevelMeter: 'LevelMeter${n} : "i4P_LevelMeter"; // ${pid} - ${label}',
    i4P_Lubricator: 'Lubricator${n} : "i4P_Lubricator"; // ${pid} - ${label}',
    i4P_pHMeter: 'pHMeter${n} : "i4P_pHMeter"; // ${pid} - ${label}',
    i4P_PostCombustor: 'PostCombustor${n} : "i4P_PostCombustor"; // ${pid} - ${label}',
    i4P_PressureMeter: 'PressureMeter${n} : "i4P_PressureMeter"; // ${pid} - ${label}',
    i4P_Rectifier: 'Rectifier${n} : "i4P_Rectifier"; // ${pid} - ${label}',
    i4P_RectifierAnod: 'RectifierAnod${n} : "i4P_RectifierAnod"; // ${pid} - ${label}',
    i4P_TemperatureMeter: 'TemperatureMeter${n} : "i4P_TemperatureMeter"; // ${pid} - ${label}',
    i4P_UFMDosingPump: 'UFMDosingPump${n} : "i4P_UFMDosingPump"; // ${pid} - ${label}',
    generic: 'Component{n} : "i4P_UFMDosingPump"; // ${pid} - ${label}',
  },
}

// const validNodesStructure = {
//   Pretrattamento: ["Vasca", "Ausiliari"]
//   /*Anaforesi: ['Vasca', 'Ausiliari', 'UF Module', 'Elettroforesi'],
//   Cataforesi: ['Vasca', 'Ausiliari', 'UF Module', 'Elettroforesi'],
//   Forni: ['Forno Polveri', 'Forno Essicatoio', 'Forno', 'PostCombustore'],
//   Sezioneapplicazionepolveri: []*/
// };

const electricItemsTypesNumber = {
  i4P_ElectricItemPump: 2,
  i4P_ElectricItemFan: 3,
  i4P_ElectricItemMotor: 1,
}

const getChildsByTypes = (node: any, types: string[]) =>
  node && node.subcomponents && node.subcomponents.filter((e: any) => types.includes(e.level))

/* const getChildsStartingWith = (node: any, startStrings: string[]) => node && node.subcomponents && node.subcomponents.filter((e: any) => {
  const regTest = new RegExp("^(" + startStrings.join('|') + ')');
  return e.data && regTest.test(e.data.label)
}); */

const getChildsByTypesRecursive = (node: any, types: string[], excludedTypes: string[]) => {
  const out: any[] = []
  const findChildByTypeRecursive = (nodeF: any, typesF: string[], excludedTypesF: string[]) => {
    if (
      typesF.includes(nodeF.level) &&
      ((nodeF.hasOwnProperty('data') && !excludedTypesF.includes(nodeF.data.nodeType)) || !nodeF.hasOwnProperty('data'))
    ) {
      out.push(nodeF)
    }

    if (nodeF.subcomponents) {
      for (const i in nodeF.subcomponents) {
        if (nodeF.subcomponents.hasOwnProperty(i)) {
          findChildByTypeRecursive(nodeF.subcomponents[i], typesF, excludedTypesF)
        }
      }
    }
  }

  findChildByTypeRecursive(node, types, excludedTypes)

  return out
}

const getChildsStartingWithRecursive = (node: any, startStrings: string[]) =>
  node &&
  node.subcomponents &&
  node.subcomponents.filter((e: any) => {
    const regTest = new RegExp('^(' + startStrings.join('|') + ')')

    if (e.data && regTest.test(e.data.label)) {
      return node
    }

    if (node.subcomponents) {
      for (const i in node.subcomponents) {
        if (node.subcomponents.hasOwnProperty(i)) {
          return getChildsStartingWithRecursive(node.subcomponents[i], startStrings)
        }
      }
    }
  })

const rTabs = (n: number) => {
  return ' '.repeat(n * 2)
}

export const getExportData = (model: any) => {
  const MacroAreas = getChildsByTypes(model.content, ['MacroArea'])
  const OEEMetrics = getChildsByTypes(model.content, ['Metric'])
  let out = ''
  let vascheCounter = 1
  const errorsOutput: any[] = []
  const sectionsCounter: any[] = []
  const electricItemsTypes: any[] = []
  const errors: any[] = []

  const getNodeRender = (node: any, areaLabel: string) => {
    const areaMetrics = getChildsByTypes(node, ['Metric'])
    const areaSections = node.subcomponents.filter((e: any) => e.level === 'Section' || e.level === 'SectionGroup')

    // let areaSectionsRender = [];
    let nodeRendered = ''

    if (templateInterpolations[areaLabel.replace(/ /g, '')]) {
      const hasMacroSection = stringInterpolations.MacroArea[areaLabel.replace(/ /g, '')] !== undefined
      if (hasMacroSection) {
        nodeRendered += rTabs(2) + stringInterpolations.MacroArea[areaLabel.replace(/ /g, '')] + ' : Struct // Tanks\n'
      }
      let componentOffset = 0
      if (areaSections) {
        areaSections.map((section: any) => {
          const componentCounter: any[] = []

          let sectionName = section.data.label.split(' - ')[0]
          let counter = 1
          let sectionMeasures

          if (sectionName.indexOf('Vasca') === 0) {
            sectionName = 'Vasca'
          }

          /*if (sectionName === 'Vasca') {
            sectionMeasures =
              rTabs(5 + (hasMacroSection ? -1 : 0)) + 'Consumption : Struct // Tank Consumption\n'+
              rTabs(6 + (hasMacroSection ? -1 : 0)) + 'ActivePower : Real; // Kwh\n' +
              rTabs(6 + (hasMacroSection ? -1 : 0)) + 'Gas : Real; // Nm³\n' +
              rTabs(6 + (hasMacroSection ? -1 : 0)) + 'ThermalEnergy : Real; // Kwh\n' +
              rTabs(6 + (hasMacroSection ? -1 : 0)) + 'DemiWater : Real; // m³\n' +
              rTabs(6 + (hasMacroSection ? -1 : 0)) + 'RawWater : Real; // m³\n' +
              rTabs(6 + (hasMacroSection ? -1 : 0)) + 'Product : Real; // L/h\n' +
              rTabs(5 + (hasMacroSection ? -1 : 0)) + 'END_STRUCT;\n';
          } else if(!hasMacroSection) {*/
          componentOffset = -1
          sectionMeasures =
            rTabs(3) +
            'Consumption : Struct // Global Consumption\n' +
            getChildsByTypes(section, ['Metric'])
              .map(
                (a: any) =>
                  rTabs(4) +
                  a.label +
                  ' : ' +
                  (a.memoryAddress && a.memoryAddress.indexOf('Boolean') > 0 ? 'Bool' : 'Real') +
                  '; // ' +
                  getUnitOfMeasurement(a.label)
              )
              .join('\n') +
            '\n' +
            rTabs(3) +
            'END_STRUCT;\n'
          /* }*/

          if (sectionName === 'Vasca') {
            counter = vascheCounter++
          } else {
            if (sectionsCounter[sectionName]) {
              sectionsCounter[sectionName] += 1
            } else {
              sectionsCounter[sectionName] = 1
            }

            counter = sectionsCounter[sectionName]
          }

          const sectionNameString = Object.keys(stringInterpolations.Component).includes(sectionName)
            ? stringInterpolations.Component[sectionName].interpolate({ n: counter })
            : sectionName

          const childNodes = getChildsByTypesRecursive(section, ['Component'], [])
            .map((c: any) => {
              if (stringInterpolations.ComponentType[c.data.nodeType]) {
                const componentTemplate = stringInterpolations.ComponentType[c.data.nodeType]

                if (!stringInterpolations.ComponentType.hasOwnProperty(c.data.nodeType)) {
                  errors['Node Type not found'].push(c.data.nodeType)
                }

                if (componentTemplate.indexOf('${n}') > -1) {
                  if (componentCounter[c.data.nodeType]) {
                    componentCounter[c.data.nodeType] += 1
                  } else {
                    componentCounter[c.data.nodeType] = 1
                  }

                  counter = componentCounter[c.data.nodeType]
                }

                const nodeRenderString = stringInterpolations.ComponentType[c.data.nodeType].interpolate({
                  n: counter,
                  pid: c.data.pid,
                  label: c.data.label,
                })

                if (Object.keys(electricItemsTypesNumber).includes(c.data.nodeType)) {
                  electricItemsTypes.push(
                    rTabs(1) +
                      stringInterpolations.MacroArea[areaLabel.replace(/ /g, '')] +
                      '.' +
                      sectionNameString +
                      '.' +
                      nodeRenderString.split(' : ')[0] +
                      '."Type" := ' +
                      electricItemsTypesNumber[c.data.nodeType] +
                      ';'
                  )
                }

                return rTabs(4 + componentOffset) + nodeRenderString
              }
              return ''
            })
            .filter(a => a)

          nodeRendered +=
            childNodes.length > 0
              ? rTabs(3 + componentOffset) +
                sectionNameString +
                ' : Struct\n' +
                sectionMeasures +
                childNodes.join('\n') +
                '\n' +
                rTabs(3 + componentOffset) +
                'END_STRUCT;\n'
              : ''

          // return nodeRendered
        })
      }

      if (hasMacroSection) {
        nodeRendered += rTabs(2) + 'END_STRUCT;'
      }

      return {
        text: templateInterpolations[areaLabel.replace(/ /g, '')].interpolate({
          areaMetrics: areaMetrics
            .map(
              (a: any) =>
                rTabs(3) +
                a.label +
                ' : ' +
                (a.memoryAddress && a.memoryAddress.indexOf('Boolean') > 0 ? 'Bool' : 'Real') +
                '; // ' +
                getUnitOfMeasurement(a.label)
            )
            .join('\n'),
          electricItemsTypes: electricItemsTypes.join(rTabs(2) + '\n'),
          areaComponents: nodeRendered,
        }),
        errors,
      }
    } else {
      // generic macroarea

      /*console.log("AREA", areaLabel, // tslint:disable-line
        areaMetrics,

        areaMetrics.map(( a: any ) => rTabs(3) + a.label + " : Real; // " + getUnitOfMeasurement(a.label)),
        areaSections
      );
*/
      const structure = getRecursiveNodeStructure(node)
      // console.log("structure.electricItemsTypes", structure.electricItemsTypes); // tslint:disable-line
      return {
        // @ts-ignore
        text: templateInterpolations.Generic.interpolate({
          areaName: stringInterpolations.MacroArea.hasOwnProperty(areaLabel.toLowerCase())
            ? stringInterpolations.MacroArea[areaLabel.toLowerCase()]
            : areaLabel.replace(/ /g, ''),
          areaNumber: MacroAreaIDs.hasOwnProperty(areaLabel.toLowerCase()) ? MacroAreaIDs[areaLabel.toLowerCase()] : '',
          areaMetrics:
            (structure.hasResetBit
              ? rTabs(2) + 'Restarti4PService : Bool; // Restart i4Paintshop service command\n'
              : '') +
            rTabs(2) +
            'Consumption : Struct // Global Consumption\n' +
            areaMetrics
              .filter((m: any) => m.type !== 'Restarti4PService')
              .map(
                (a: any) =>
                  rTabs(3) +
                  a.label +
                  ' : ' +
                  (a.memoryAddress && a.memoryAddress.indexOf('Boolean') > 0 ? 'Bool' : 'Real') +
                  '; // ' +
                  getUnitOfMeasurement(a.label)
              )
              .join('\n') +
            '\n' +
            rTabs(2) +
            'END_STRUCT;\n',
          electricItemsTypes: structure.electricItemsTypes.join(rTabs(2) + '\n'),
          areaComponents: structure.output,
        }),
        errors,
      }
    }
  }

  const getRecursiveNodeStructure = (nodes: any) => {
    let output = ''
    let counter = 1
    // tslint:disable-next-line:no-shadowed-variable
    let sectionsCounter: any[] = []
    // tslint:disable-next-line:no-shadowed-variable
    const electricItemsTypes: any[] = []
    let areaLabel = ''
    let areaLabelText = ''
    let sectionNameString = ''
    let nodeRenderString = ''
    let currentSectionName = ''
    const resetMetrics = getChildsByTypesRecursive(nodes, ['Metric'], []).find((e: any) => e.type === 'nodes')
    const hasResetBit = !resetMetrics

    const getRecursiveNodes = (node: any, level: number) => {
      if (node.level === 'MacroArea') {
        areaLabel = node.data.label.replace(/ /g, '')
        areaLabelText = stringInterpolations.MacroArea.hasOwnProperty(areaLabel.toLowerCase())
          ? stringInterpolations.MacroArea[areaLabel.toLowerCase()]
          : areaLabel
      }

      // render MacroArea or Section
      if (
        !['Metric', 'Component', 'ComponentGroup', 'SectionGroup'].includes(node.level) ||
        (['Section', 'SectionGroup'].includes(node.level) && node.data.label.toLowerCase() === 'ausiliari')
      ) {
        let sectionName

        sectionName = node.data.label.split(' - ')[0]
        sectionName = sectionName.indexOf('Vasca') === 0 ? 'Vasca' : node.data.label

        if (sectionName === 'Vasca') {
          counter = vascheCounter++
        } else {
          if (sectionsCounter[sectionName]) {
            sectionsCounter[sectionName] += 1
          } else {
            sectionsCounter[sectionName] = 1
          }
          counter = sectionsCounter[sectionName]
        }

        sectionNameString = Object.keys(stringInterpolations.Component).includes(sectionName)
          ? stringInterpolations.Component[sectionName].interpolate({ n: counter })
          : sectionName

        if (['Section', 'SectionGroup'].includes(node.level)) {
          currentSectionName = sectionNameString
        }

        // reset component counter per section
        if (node.level === 'Section') {
          sectionsCounter = []
        }

        output += rTabs(level) + '"' + sectionNameString + '"' + ' : Struct // ' + node.data.label + '\n' // add translations
      }

      // render component
      if (node.level === 'Component' && node.data.nodeType !== 'i4P_NoNodeType') {
        if (sectionsCounter[node.data.nodeType]) {
          sectionsCounter[node.data.nodeType] += 1
        } else {
          sectionsCounter[node.data.nodeType] = 1
        }
        counter = sectionsCounter[node.data.nodeType]
        // console.log('counter', sectionsCounter, node.data.nodeType, counter) // tslint:disable-line

        if (!stringInterpolations.ComponentType.hasOwnProperty(node.data.nodeType)) {
          console.log('invalid compo node type', node) // tslint:disable-line
          if (!errorsOutput[currentSectionName]) {
            errorsOutput[currentSectionName] = {
              Component: [],
            }
          }
          errorsOutput[currentSectionName].Component.push(node.data.label + ': invalid node type')
        }
        const componentInterpolation = stringInterpolations.ComponentType.hasOwnProperty(node.data.nodeType)
          ? stringInterpolations.ComponentType[node.data.nodeType]
          : stringInterpolations.ComponentType.generic

        nodeRenderString = componentInterpolation.interpolate({
          n: counter,
          pid: node.data.pid,
          label: node.data.label,
        })

        output += rTabs(level) + nodeRenderString + '\n'

        if (Object.keys(electricItemsTypesNumber).includes(node.data.nodeType)) {
          electricItemsTypes.push(
            rTabs(1) +
              areaLabelText +
              '."' +
              currentSectionName +
              '".' +
              nodeRenderString.split(' : ')[0] +
              '."Type" := ' +
              electricItemsTypesNumber[node.data.nodeType] +
              ';'
          )
        }
      }

      // get metrics and continue recursion
      if (node.subcomponents) {
        const metrics = node.subcomponents.filter((c: any) => c.level === 'Metric' && c.type !== 'Restarti4PService')

        if (metrics && metrics.length > 0 && node.level !== 'Component') {
          if (node.level !== 'MacroArea') {
            output += rTabs(level + 1) + 'Consumption : Struct // ' + node.level + ' consumption\n'
            for (const m in metrics) {
              if (metrics.hasOwnProperty(m)) {
                output +=
                  rTabs(level + 2) +
                  metrics[m].label +
                  ' : ' +
                  (metrics[m].memoryAddress && metrics[m].memoryAddress.indexOf('Boolean') > 0 ? 'Bool' : 'Real') +
                  '; // ' +
                  getUnitOfMeasurement(metrics[m].type) +
                  '\n'
              }
            }
            output += rTabs(level + 1) + 'END_STRUCT;\n'
          }
        }

        if (node.subcomponents) {
          for (const i in node.subcomponents) {
            if (node.subcomponents.hasOwnProperty(i)) {
              if (
                !(
                  node.subcomponents[i].level === 'Metric' ||
                  (node.subcomponents[i].level === 'Component' &&
                    node.subcomponents[i].data.nodeType === 'i4P_NoNodeType')
                )
              ) {
                if (getChildsByTypesRecursive(node.subcomponents[i], ['Component'], ['i4P_NoNodeType']).length > 0) {
                  getRecursiveNodes(
                    node.subcomponents[i],
                    level +
                      (['Section', 'SectionGroup'].includes(node.level) && node.data.label.toLowerCase() === 'ausiliari'
                        ? 2
                        : ['Metric', 'Component', 'ComponentGroup', 'SectionGroup'].includes(node.level)
                        ? 0
                        : 1)
                  )
                }
              }
            }
          }
        }
      }

      if (node.level === 'MacroArea') {
        // no metrics nor components, output bool placeholder

        let childNodes = 0
        const childNotMetrics = node.subcomponents.filter((a: any) => a.level !== 'Metric')

        for (const cn in childNotMetrics) {
          if (childNotMetrics.hasOwnProperty(cn)) {
            childNodes += getChildsByTypesRecursive(childNotMetrics[cn], ['Metric'], []).length
          }
        }
        if (getChildsByTypesRecursive(node, ['Component'], ['i4P_NoNodeType']).length === 0 && childNodes === 0) {
          output += rTabs(level + 1) + 'None : Bool; // Work in progress\n'
        }
      }

      // close MacroArea or Section
      if (
        !['Metric', 'Component', 'ComponentGroup', 'SectionGroup'].includes(node.level) ||
        (['Section', 'SectionGroup'].includes(node.level) && node.data.label.toLowerCase() === 'ausiliari')
      ) {
        if ('Section' === node.level) {
          sectionsCounter = []
        }

        output += rTabs(level) + 'END_STRUCT;' + '\n'
      }
    }

    getRecursiveNodes(nodes, 2)

    return {
      output,
      electricItemsTypes,
      hasResetBit,
    }
  }

  const getOEERender = (areaLabel: any, metrics: any) => {
    if (templateInterpolations[areaLabel] && metrics) {
      return templateInterpolations[areaLabel].interpolate({
        areaMetrics: metrics,
        timeMetrics: metrics
          .filter((m: any) => m.type === 'PlantStatusChange')
          .map((m: any) => rTabs(6) + m.label + ' : Bool; // ' + getUnitOfMeasurement(m.label))
          .join('\n'),
        productionMetrics: metrics
          .filter((m: any) => m.type === 'FramePieces')
          .map((m: any) => rTabs(6) + m.label + ' : DInt; // ' + getUnitOfMeasurement(m.label))
          .join('\n'),
        globalMetrics: metrics
          .filter((m: any) => !['PlantStatusChange', 'WasteCause', 'FramePieces', 'Restarti4PService'].includes(m.type))
          .map(
            (m: any) =>
              rTabs(5) +
              m.label +
              ' : ' +
              (m.memoryAddress && m.memoryAddress.indexOf('Boolean') > 0 ? 'Bool' : 'Real') +
              '; // ' +
              getUnitOfMeasurement(m.type)
          )
          .join('\n'),
        electricItemsTypes: '\n',
      })
    }
  }

  out += getOEERender('Oee', OEEMetrics) + '\n\n\n'

  for (const i in MacroAreas) {
    if (MacroAreas.hasOwnProperty(i)) {
      const area = MacroAreas[i]
      const areaLabel = area.data.label

      /*if (Object.keys(validNodesStructure).includes(areaLabel.replace(/ /g, ''))) {*/
      const nodeRender = getNodeRender(area, areaLabel)
      if (nodeRender) {
        out += nodeRender.text + '\n\n\n'
        if (nodeRender.errors && nodeRender.errors.length > 0) {
          errorsOutput[areaLabel] = {}
          errorsOutput[areaLabel] = nodeRender.errors
        }
      }
      /*} else {
        if (!errorsOutput.hasOwnProperty('MacroArea not found')) {
          errorsOutput['MacroArea not found'] = []
        }
        errorsOutput['MacroArea not found'].push(areaLabel)
      }*/
    }
  }

  let errorsText = ''

  if (errorsOutput !== []) {
    errorsText += 'ERRORS\n----------------------------\n'
    Object.keys(errorsOutput).map(e => {
      const errs = errorsOutput[e]
      errorsText += e + '\n===============\n'
      Object.keys(errs).map(err => {
        errorsText += ' - ' + errs[err] + '\n'
      })
      errorsText += '\n'
    })
  }

  return {
    plcText: out,
    errorsText,
  }
}
