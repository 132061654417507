import { LotCostListItem, ProductionListItem } from '../types/ProductionListItem'

export const initialProductionState = {
  hasErrored: false,
  isLoading: false,
  hasDetailsErrored: false,
  isDetailsLoading: false,
  lot: null,
  items: [],
  hasAlarmsErrored: false,
  isAlarmsLoading: false,
  itemsAlarms: [],
  itemsCosts: [],
  downloadHasErrored: false,
  downloadIsLoading: false,
  downloadItems: [],
  lotsConsumed: [],
  lotsConsumedHasError: false,
  lotsConsumedIsLoading: false
} as ProductionState

export interface ProductionState {
  hasErrored: boolean
  isLoading: boolean
  hasDetailsErrored: boolean
  isDetailsLoading: boolean
  lot: ProductionListItem | null
  items: ProductionListItem[]
  hasAlarmsErrored: boolean
  isAlarmsLoading: boolean
  itemsAlarms: ProductionListItem[]
  itemsCosts: LotCostListItem[]
  downloadHasErrored: boolean
  downloadIsLoading: boolean
  downloadItems: ProductionListItem[]
  lotsConsumed: any
  lotsConsumedHasError: boolean
  lotsConsumedIsLoading: boolean
}
