// tslint:disable-next-line:only-arrow-functions
export const extend = function () {
  const extended = {};
  let deep = false;
  let i = 0;

  if (typeof (arguments[0]) === 'boolean') {
    deep = arguments[0];
    i++;
  }

  // tslint:disable-next-line:only-arrow-functions
  const merge = function (obj: { [x: string]: any; hasOwnProperty: (arg0: string) => any; }) {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        if (deep && Object.prototype.toString.call(obj[prop]) === '[object Object]') {
          // @ts-ignore
          extended[prop] = extend(true, extended[prop], obj[prop]);
        } else {
          extended[prop] = obj[prop];
        }
      }
    }
  };

  for (; i < arguments.length; i++) {
    merge(arguments[i]);
  }

  return extended;
};
