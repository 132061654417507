import { i18next as i18n, i18nextInit } from '@mv-submodules/inplant-core-fe/i18n/i18n'
import core from '@mv-submodules/inplant-core-fe/i18n'
import designer from '@mv-submodules/inplant-designer-fe/i18n'
import director from '../i18n'
import overwrites from '../i18n/overwrite'
import efficiency from '@mv-submodules/inplant-efficiency-fe-imel/i18n'
import environment from '@mv-submodules/inplant-environment-fe-imel/i18n'
import maintenance from '@mv-submodules/inplant-maintenance-fe/i18n'
import plantstatusovertime from '@mv-submodules/inplant-plantstatusovertime-fe-imel/i18n'
import production from '@mv-submodules/inplant-production-fe-imel/i18n'
import service from '@mv-submodules/inplant-service-fe/i18n'
import smartdocs from '@mv-submodules/inplant-smartdocs-fe/i18n'
import user from '@mv-submodules/inplant-user-fe/i18n'

import {extend} from '@mv-submodules/inplant-core-fe/functions/objects'

i18nextInit({
  // @ts-ignore
  resources: extend(true, {
    en: {
      translation: {
        ...director.en,
        ...core.en,
        ...designer.en,
        ...efficiency.en,
        ...environment.en,
        ...maintenance.en,
        ...plantstatusovertime.en,
        ...production.en,
        ...service.en,
        ...smartdocs.en,
        ...user.en,
      },
    },
    it: {
      translation: {
        ...director.it,
        ...core.it,
        ...designer.it,
        ...efficiency.it,
        ...environment.it,
        ...maintenance.it,
        ...plantstatusovertime.it,
        ...production.it,
        ...service.it,
        ...smartdocs.it,
        ...user.it,
      },
    },
  }, {
    en: {
      translation: {
        ...overwrites.en,
      }
    },
    it: {
      translation: {
        ...overwrites.it,
      }
    }
  })
})

export default i18n
