export const plantMetrics = [
  {
    id: 0,
    label: 'media',
    type: 'section',
    data: [
      {
        id: 0,
        label: 'plant',
        measures: [
          { memory: 'plantActivePower', name: 'ActivePower', unit: 'kWh' },
          { memory: 'plantGas', name: 'Gas', unit: 'Nm³' },
          { memory: 'plantRawWater', name: 'RawWater', unit: 'm³' },
          { memory: 'plantComprimedAir', name: 'ComprimedAir', unit: 'm³' },
        ],
      },
    ],
  },
];

export const mappedInfluxIDs = {
  pieces: 'IMPRAJCRDGOJIISE',
  qualityMeasure: 'pcs'
}
