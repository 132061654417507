import * as React from 'react'
import { connect } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import { withRouter } from 'react-router'
import { Bar, ComposedChart, Label, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import * as moment from 'moment'
import { AlertNoData } from '@mv-submodules/inplant-efficiency-fe-imel/ui/components/widgets/SharedComponents/sharedComponents'
import { Card, CardBody, CardFooter, CardHeader } from '@mv-submodules/inplant-components-fe'
import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'
import Column from '@mv-submodules/inplant-components-fe/ui/components/Grid/Column'
import { fetchPiecesData } from '../../../../redux/actions/graphs'

interface GraphPlanRunStateProps {
  data: any
  dateEnd: string | null
  dateStart: string | null
  isLoading: boolean
  lastDateUpdate: number | null
  prevRange: string | null
  range: string
  measureUnits: object
  cliccable?: boolean
}

interface GraphPiecesConsumedDispatchProps {
  fetchPiecesConsumedData: () => Function
}

interface GraphPlanRunOwnProps {
  // t: TranslationFunction
  history: any
}

interface GraphPlanRunOwnState {
  lastUpdate: number | null
}

type GraphPlanRunProps = GraphPlanRunStateProps &
  GraphPiecesConsumedDispatchProps &
  GraphPlanRunOwnProps &
  WithTranslation

const mapStateToProps = (state: any): GraphPlanRunStateProps => {
  return {
    data: state.production.lotsConsumed,
    dateEnd: state.dateFilters.dateEnd,
    dateStart: state.dateFilters.dateStart,
    isLoading: state.production.lotsConsumedIsLoading,
    lastDateUpdate: state.dateFilters.lastUpdate,
    prevRange: state.dateFilters.prevRange,
    range: state.dateFilters.range,
    measureUnits: state.config.generic.measureUnits || {},
  }
}

const mapDispatchToProps = (dispatch: Function): GraphPiecesConsumedDispatchProps => {
  return {
    fetchPiecesConsumedData: () => dispatch(fetchPiecesData()),
  }
}

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active) {
    return (
      <div className="recharts-default-tooltip">
        <ul>
          <li className="title">
            <strong>{moment(label).format('DD/MM')}</strong>
          </li>
          {payload &&
            payload.map((line: any, index: number) => (
              <li key={index}>
                <strong>{line.name === 'runTimeOperatingTime' ? 'Run/Operating Time %' : line.name}</strong>
                <span className="value float-right">
                  {typeof line.value === 'number' ? line.value.toFixed(2) : line.value}
                </span>
              </li>
            ))}
        </ul>
      </div>
    )
  }

  return null
}

class GraphPiecesLoadedUnloaded extends React.Component<GraphPlanRunProps, GraphPlanRunOwnState> {
  constructor(props: GraphPlanRunProps) {
    super(props)
    this.state = {
      lastUpdate: null,
    }
  }

  public componentWillMount() {
    this.props.fetchPiecesConsumedData()
  }

  public componentWillReceiveProps(newProps: GraphPlanRunStateProps) {
    if (newProps.lastDateUpdate !== null && this.state.lastUpdate !== newProps.lastDateUpdate) {
      this.setState({
        lastUpdate: newProps.lastDateUpdate,
      })
      this.props.fetchPiecesConsumedData()
    }
  }

  public render() {
    // const {dateStart, dateEnd, TPRNCData} = this.props

    if (this.props.isLoading) {
      return (
        <Column key={Date.now()}>
          <label className="text-center">
            <i className="fas fa-2x fa-spin fa-circle-notch" />
          </label>
        </Column>
      )
    }

    const tempData = this.props.data.results && this.props.data.results[0].series.map((d:any) => d.values)
    
    const loaded = tempData ? tempData[0] : []
    const downloaded = tempData ? tempData[1] : []
    const graphData: object[] = []
    loaded.forEach((d:any, index:number) => {
      if(downloaded[index][0] === d[0]) {
        graphData.push({
          time: d[0],
          loaded: d[1],
          unloaded: downloaded[index][1]
        })
      }
    });
    // const data =
    // this.props.data &&
    // this.props.data.map((e: any, i: number) => {
    //   consoleLog('e', e)
    //     return {
    //       loaded: e[0],
    //       download: e[1],
    //     }
    //   })

    const timeFormat =
      this.props.range &&
      ['dateRangeLastYear', 'dateRangeYear', 'dateRange6Months', 'dateRangeLast6Month'].includes(this.props.range)
        ? 'MM/YYYY'
        : 'DD/MM'

    const dataLabels = graphData && graphData.length > 0 ? Object.keys(graphData[0]).filter(e => e !== 'time') : []
    let downloadData: string[][] = graphData
      ? graphData.map((e: any) => {
          return [
            moment(e.time).format(timeFormat),
            e[dataLabels[0]] * 3600,
            e[dataLabels[1]] * 3600,
            e[dataLabels[2]] * 3600,
            e[dataLabels[3]],
          ]
        })
      : []

    downloadData = [
      ['time', ...dataLabels].map(
        (c: string) => `${c}${this.props.measureUnits[c] ? ` (${this.props.measureUnits[c]})` : ''}`
      ),
    ].concat(downloadData)

    // const dates: DateRange = getDatesFromRange(this.props.range)
    // const tprncData = TPRNCAlarmsData(dateStart, dateEnd, data, TPRNCData)

    return (
      <div className={'DashboardWidget WidgetPlanRun WidgetH50 ' + (this.props.cliccable && 'cliccable')}>
        <Card marginBottom={3}>
          <CardHeader>{this.props.t('production.cardTitle.consumedLots')}</CardHeader>
          <CardBody>
            <div className="container-fluid chart-container">
              <div className="full-h">
                <Row verticalAlignment={'center'}>
                  <Column>
                    <div className="text-center full-h">
                      <div className="chart-container chartsPlanRunHome">
                        {graphData ? (
                          <ResponsiveContainer key={'key'}>
                            <ComposedChart
                              width={700}
                              height={450}
                              data={graphData}
                              margin={{
                                top: 45,
                                right: 30,
                                left: 0,
                                bottom: 0,
                              }}
                              barGap={1}
                              className={this.props.cliccable ? 'cliccable' : ''}
                            >
                              <XAxis
                                dataKey="time"
                                tickFormatter={e => {
                                  return moment(e).format(timeFormat)
                                }}
                                axisLine={false}
                              />
                              <YAxis axisLine={false} yAxisId={'left'}>
                                <Label position={'top'} offset={30}>
                                  Pieces
                                </Label>
                              </YAxis>
                              <Tooltip content={<CustomTooltip />} cursor={{ fill: 'none' }} />
                              <Bar
                                dataKey="loaded"
                                fill="#98abc5"
                                onMouseOver={(a: any, b: any, c: any) => {
                                  if (c && c.native) {
                                    c.native.target.opacity = 0.3
                                  }
                                }}
                                yAxisId={'left'}
                              />
                              <Bar
                                dataKey="unloaded"
                                fill="#7b6888"
                                onMouseOver={(a: any, b: any, c: any) => {
                                  if (c && c.native) {
                                    c.native.target.opacity = 0.3
                                  }
                                }}
                                yAxisId={'left'}
                              />
                            </ComposedChart>
                          </ResponsiveContainer>
                        ) : (
                          <AlertNoData message={this.props.t('i4efficiency.fetch.noData')} />
                        )}
                      </div>
                    </div>
                  </Column>
                </Row>
              </div>
            </div>
          </CardBody>
          <CardFooter textSizeSmall={true} textAlign={'right'} textMuted={true}>
            <ul className="legend float-left">
              <li>
                <span className="legend-color legend-planned-color legend-color-block" /> Loaded
              </li>
              <li>
                <span className="legend-color legend-operating-color" /> Unloaded
              </li>
            </ul>
          </CardFooter>
        </Card>
      </div>
    )
  }
}

export default withRouter<any, any>(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(GraphPiecesLoadedUnloaded))
)
