import { API } from './index'
import { AnyAction, Dispatch } from 'redux'
import { logoutUser } from '@mv-submodules/inplant-core-fe/auth'
import { ModelObject } from '../../types/designer'

export const isFetching = (bool: boolean): AnyAction => {
  return {
    type: 'DESIGNER_IS_FETCHING',
    fetching: bool,
  }
}

export const fetchSuccess = (dataStructure: object): AnyAction => {
  return {
    type: 'DESIGNER_FETCH_SUCCESS',
    data: dataStructure,
  }
}

export const fetchError = (error: Error): AnyAction => {
  return {
    payload: error,
    type: 'DESIGNER_FETCH_ERROR',
  }
}

export const fetchData = (): ((dispatch: Dispatch<AnyAction>) => Promise<void>) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    dispatch(isFetching(true))

    try {
      const data = await API().request('/model')

      return Promise.all([data]).then(values => {
        dispatch(isFetching(false))
        dispatch(fetchSuccess(values[0]))

        return values[0]
      })
    } catch (error: any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }

      dispatch(isFetching(false))
      dispatch(fetchError(new Error(error)))
      throw error
    }
  }
}

export const isSubmitting = (bool: boolean): AnyAction => {
  return {
    type: 'DESIGNER_IS_SUBMITTING',
    fetching: bool,
  }
}

export const submitSuccess = (): AnyAction => {
  return {
    type: 'DESIGNER_SUBMIT_SUCCESS',
  }
}

export const submitError = (error: Error): AnyAction => {
  return {
    payload: error,
    type: 'DESIGNER_SUBMIT_ERROR',
  }
}

export const submitData = (model: ModelObject): ((dispatch: Dispatch<AnyAction>) => Promise<void>) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    dispatch(isSubmitting(true))

    try {
      const submit = await API().request(`/model/update-model`, {
        headers: {
          'content-type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(model),
      })

      dispatch(submitSuccess())
      return submit
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }

      dispatch(isSubmitting(false))
      dispatch(submitError(new Error(error)))
      throw error
    }
  }
}
