import * as React from 'react'
import FlagIcon from './FlagIcon'
import { RouteComponentProps, withRouter } from 'react-router'
import { withTranslation, WithTranslation } from 'react-i18next'
import { NodeType, TemplateField } from '@mv-submodules/inplant-designer-fe/types'
import { memoryFromAddress } from '@mv-submodules/inplant-designer-fe/functions/shared'
import { findPD } from '@mv-submodules/inplant-designer-fe/functions/nodesFunctions'
import { Metric } from '@mv-submodules/inplant-designer-fe/types/templates'
import { i18nLabels } from '@mv-submodules/inplant-designer-fe/types/i18n'
import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'

export interface StateProps {
  data: any
  languageSelected: null | string
  model: NodeType
  save: Function
  cancel: Function
}

export interface OwnProps extends RouteComponentProps<any> {
  data: any
  // t: TranslationFunction
}

export interface OwnState {
  metricData: any
  metricErrors: any
  translations: { [lang: string]: string }
}

export type Props = StateProps & OwnProps & WithTranslation

class MetricFormComponent extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      metricData: [],
      metricErrors: {},
      translations: {},
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleInputBlur = this.handleInputBlur.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
  }

  public componentDidMount() {
    let metricData
    if (this.props.data) {
      metricData = JSON.parse(JSON.stringify(this.props.data))

      if (this.props.data.memoryAddress && !this.props.data.memory) {
        const memory = memoryFromAddress(this.props.data.memoryAddress)
        console.log('dataType', this.props.data.memoryAddress, this.props.data.type, memory) // tslint:disable-line
        if (memory) {
          metricData.memory = memory
          metricData.dataType = memory.prefix + memory.sufix
        }
      } else {
        console.log('dataType 2', this.props.data.memoryAddress, this.props.data.memory, metricData) // tslint:disable-line
      }

      if (!this.props.data.polling) {
        metricData.polling = false
      }
      if (!this.props.data.savingMode) {
        metricData.savingMode = 'Other'
      }
    } else {
      console.log('no metrics data', this.props) // tslint:disable-line
      metricData = []
    }

    this.setState({
      metricData,
    })
  }

  public componentWillUnmount() {
    this.setState({
      metricData: [],
      metricErrors: {},
      translations: {},
    })
  }

  private handleInputChange(event: React.ChangeEvent<any>) {
    const target = event.currentTarget
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    if (name === 'label' && this.props.languageSelected) {
      const translations = this.props.data.translations || {}
      translations[this.props.languageSelected] = value

      this.setState({
        translations,
      })
    } else {
      const metricData = { ...this.state.metricData }
      metricData[name] = value

      this.setState({
        metricData,
      })
    }
  }

  private handleInputBlur(event: React.ChangeEvent<any>) {
    const target = event.currentTarget
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    if (name === 'fpid' && value !== '') {
      const father = findPD(value, this.props.model)

      if (!father) {
        const metricErrors = this.state.metricErrors
        metricErrors.fpid = this.props.t('designer.node.errors.invalidFPD')

        this.setState({
          metricErrors,
        })
      } else {
        const metricErrors = this.state.metricErrors
        delete metricErrors.fpid

        this.setState({
          metricErrors,
        })
      }
    }

    return true
  }

  private handleSave(e: React.FormEvent) {
    e.preventDefault()
    this.props.save(this.state.metricData)
  }

  private handleCancel() {
    this.props.cancel()
  }

  public render() {
    return (
      <React.Fragment>
        <form onSubmit={this.handleSave}>
          <div className="section-form">
            <Row>
              {Metric.fields.map((field: TemplateField, id: number) => (
                <React.Fragment key={id}>
                  {field.type === 'string' && (
                    <div className={'form-group col-' + field.width}>
                      <label className="mr-2" htmlFor={field.name}>
                        {this.props.t('designer.node.attributes.' + field.label)}
                      </label>
                      <div className="input-group">
                        <input
                          id={field.name}
                          className={
                            'form-control form-control-sm' +
                            (this.state.metricErrors && this.state.metricErrors[field.name] ? ' is-invalid' : '')
                          }
                          type="text"
                          name={field.name}
                          required={field.required}
                          value={
                            this.props.languageSelected && field.name === 'label'
                              ? this.state.translations[this.props.languageSelected] || ''
                              : this.state.metricData[field.name] || ''
                          }
                          onChange={this.handleInputChange}
                          onBlur={this.handleInputBlur}
                        />
                        {this.state.metricErrors && this.state.metricErrors[field.name] && (
                          <div className="invalid-feedback">{this.state.metricErrors[field.name]}</div>
                        )}
                        {this.props.languageSelected && field.translatable && (
                          <div className="input-group-append">
                          <span className="input-group-text">
                            <FlagIcon code={i18nLabels[this.props.languageSelected]} />
                          </span>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {field.type === 'boolean' && (
                    <div className={'form-check form-group col-' + field.width}>
                      <input
                        id={field.name}
                        className="form-check-input"
                        type="checkbox"
                        name={field.name}
                        required={field.required}
                        value={this.state.metricData[field.name] || ''}
                        onChange={this.handleInputChange}
                      />
                      <label className="mr-2 form-check-label" htmlFor={field.name}>
                        {this.props.t('designer.node.attributes.' + field.label)}
                      </label>
                    </div>
                  )}
                  {field.type === 'enum' && field.values && (
                    <div className={'form-group col-' + field.width}>
                      <label className="mr-2" htmlFor={field.name}>
                        {this.props.t('designer.node.attributes.' + field.label)}
                      </label>
                      <select
                        id={field.name}
                        className="form-control form-control-sm"
                        name={field.name}
                        required={field.required}
                        onChange={this.handleInputChange}
                        value={String(this.state.metricData[field.name]) || ''}
                      >
                        <option />
                        {field.values.map((en: any) => {
                          if (en === '#SEPARATOR#') {
                            return <option disabled={true}>_____________</option>
                          } else {
                            return (
                              <option value={en !== null ? String(en) : ''} key={en}>
                                {en === false || en === 'false'
                                  ? this.props.t('designer.values.false')
                                  : en === true || en === 'true'
                                    ? this.props.t('designer.values.true')
                                    : en}
                              </option>
                            )
                          }
                        })}
                      </select>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </Row>

          </div>
          <div className="section-actions mb-5">
            <button className="btn btn-primary btn-sm float-right ml-3 mb-3" type="submit">
              {this.props.t('designer.node.metric.save')}
            </button>
            <button className="btn btn-warning btn-sm float-right ml-3 mb-3" onClick={this.handleCancel}>
              {this.props.t('designer.node.metric.cancel')}
            </button>
          </div>
        </form>
      </React.Fragment>
    )
  }
}

export default withRouter<any, any>(withTranslation()(MetricFormComponent))
