import { Template } from '../index'
import { Component } from './component'
import { Metric } from './metric'

export const ComponentGroup: Template = {
  id: 'componentGroup',
  name: 'ComponentGroup',
  version: '0.1.0',
  date: '2018-11-08',
  fields: [
    {
      name: 'label',
      type: 'string',
      label: 'componentGroup.label',
      width: 12,
      required: true,
      nodePath: 'data',
      translatable: true,
    },
    {
      name: 'hasQRCode',
      type: 'boolean',
      label: 'componentGroup.hasQRCode',
      width: 4,
      nodePath: 'data',
      values: [true]
    },
    /*{
      "name": "id",
      "type": "string",
      "label": "componentGroup.id",
      "width": 4,
      "required": true,
      "nodePath": "data"
    },*/
    /*{
      "name": "pid",
      "type": "string",
      "label": "componentGroup.pid",
      "width": 4,
      "nodePath": "data"
    },
    {
      "name": "fpid",
      "type": "string",
      "label": "componentGroup.fpid",
      "width": 4,
      "nodePath": "data"
    },*/
  ],
  copyable: true,
  allowedSubcomponents: [Component, Metric],
}
