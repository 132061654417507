import { Template } from '../index'
import { Metric } from './metric'

export const Component: Template = {
  id: 'component',
  name: 'Component',
  version: '0.1.0',
  date: '2018-11-09',
  fields: [
    {
      name: 'label',
      type: 'string',
      label: 'component.label',
      width: 12,
      required: true,
      nodePath: 'data',
      translatable: true,
    },
    /*    {
      "name": "dataBlock",
      "type": "string",
      "label": "component.dataBlock",
      "width": 12
    },*/
    /*{
      "name": "id",
      "type": "string",
      "label": "component.id",
      "width": 4,
      "required": true,
      "nodePath": "data"
    },*/
    {
      name: 'pid',
      type: 'string',
      label: 'component.pid',
      width: 4,
      nodePath: 'data',
    },
    {
      name: 'fpid',
      type: 'string',
      label: 'component.fpid',
      width: 4,
      nodePath: 'data',
    },
    {
      name: 'hasQRCode',
      type: 'boolean',
      label: 'component.hasQRCode',
      width: 4,
      nodePath: 'data',
      values: [true]
    },
    {
      name: 'nodeType',
      type: 'enum',
      label: 'component.nodeType',
      values: [
        'i4P_BlastingMachine',
        'i4P_Boiler',
        'i4P_Burner',
        'i4P_Chiller',
        'i4P_CompressedAirMeter',
        'i4P_ConductivityMeter',
        'i4P_Consumption',
        'i4P_DosingPump',
        'i4P_ElectricItem',
        'i4P_ElectricItemMotor',
        'i4P_ElectricItemFan',
        'i4P_ElectricItemPump',
        'i4P_FlowMeter',
        'i4P_LevelMeter',
        'i4P_Lubricator',
        'i4P_pHMeter',
        'i4P_PostCombustor',
        'i4P_PressureMeter',
        'i4P_Rectifier',
        'i4P_RectifierAnod',
        'i4P_TemperatureMeter',
        'i4P_UFMDosingPump',
        'i4P_NoNodeType',
      ],
      width: 12,
      required: false,
    },
  ],
  copyable: true,
  allowedSubcomponents: [Metric],
}
