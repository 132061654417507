import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { SideContentParams } from '@mv-submodules/inplant-designer-fe/types'
import { popupNotification } from '@mv-submodules/inplant-core-fe/functions/notifications'
import Column from "@mv-submodules/inplant-components-fe/ui/components/Grid/Column";
import Row from "@mv-submodules/inplant-components-fe/ui/components/Grid/Row";
import IconComponent from '../../../../../inplant-components-fe/ui/components/MVIcon/Icon';

export interface TreeNodeOwnProps {
  data: any
  key: string
  id: string
  clickLeft: () => void
  clickRight: (id: string) => void
  clickEdit: (id: string, parent: string, path: string) => void
  clickCopy: (id: string, parent: string, path: string) => void
  clickCut: (id: string, parent: string, path: string) => void
  clickDelete: (path: string) => void
  clickAdd: (id: string, parent: string, path: string) => void
  clickMove: (path: string, direction: number) => void
  onSidecontentChange: (params: SideContentParams) => void
  selectNode: (id: string, parent: string) => void
  parent: any
  path: string
  root: string | null
  currentActive: boolean
}

export interface TreeNodeOwnState {
  showDetails: boolean
  showDetailsSection: string | null
  detailsLoading: boolean
  detailsReady: boolean
  docs: any
  columns: string[]
}

export type TreeNodeProps = TreeNodeOwnProps & WithTranslation

const defaultColumns = ['Filename', 'Version', 'Date']

class TreeNode extends React.Component<TreeNodeProps, TreeNodeOwnState> {
  constructor(props: TreeNodeProps) {
    super(props)
    this.state = {
      showDetails: false,
      showDetailsSection: null,
      detailsLoading: false,
      detailsReady: false,
      docs: [],
      columns: defaultColumns,
    }

    this.rightClick = this.rightClick.bind(this)
    this.leftClick = this.leftClick.bind(this)
    this.editClick = this.editClick.bind(this)
    this.copyClick = this.copyClick.bind(this)
    this.cutClick = this.cutClick.bind(this)
    this.deleteClick = this.deleteClick.bind(this)
    this.addClick = this.addClick.bind(this)
    this.moveUp = this.moveUp.bind(this)
    this.moveDown = this.moveDown.bind(this)
  }

  private rightClick() {
    this.props.clickRight(this.props.path + '.' + this.props.id)
  }

  private editClick() {
    this.props.clickEdit(this.props.id, this.props.parent, this.props.path)
  }

  private copyClick() {
    this.props.clickCopy(this.props.id, this.props.parent, this.props.path)
  }

  private cutClick() {
    this.props.clickCut(this.props.id, this.props.parent, this.props.path)
  }

  private addClick() {
    this.props.clickAdd(this.props.id, this.props.parent, this.props.path)
  }

  private deleteClick() {
    this.props.clickDelete(this.props.path + '.' + this.props.id)
  }

  private moveUp() {
    this.props.clickMove(this.props.path + '.' + this.props.id, -1)
  }

  private moveDown() {
    this.props.clickMove(this.props.path + '.' + this.props.id, 1)
  }

  private leftClick() {
    this.props.clickLeft()
  }

  private selectNode() {
    this.props.selectNode(this.props.id, this.props.parent)
  }

  private copyText(text: string) {
    const textArea = document.createElement('textarea')
    textArea.value = text
    document.body.appendChild(textArea)
    textArea.select()
    document.execCommand('Copy')
    textArea.remove()

    popupNotification({
      text: this.props.t('designer.copy.success'),
      type: 'success'
    })
  }

  public render() {
    return (
      <div className={'cards card-level' + (this.props.currentActive ? ' active' : '')} data-id={this.props.data.id}>
        <div className="card-inner">
          <div className="card-title">
            <Row>
              <div onClick={() => this.selectNode()}>
                <Column md={12} >
                  <h4>{this.props.data.data.label}</h4>
                  {this.props.data.data.pid && (
                    <p>
                      <strong>P&amp;ID:</strong> {this.props.data.data.pid}
                    </p>
                  )}
                  {this.props.data.data.id && (
                    <p
                      className="mt-1 tooltips copyable"
                      onClick={() => this.copyText(this.props.data.data.id)}
                      data-title={this.props.t('designer.copy.click')}
                    >
                      <strong>ID:</strong> {this.props.data.data.id}
                    </p>
                  )}
                </Column>
              </div>
            </Row>
          </div>
        </div>

        {this.props.data.data.hasQRCode && (
          <span className={'node-has-qrcode'} title={this.props.t('designer.node.attributes.hasQRCode')}>
            <IconComponent icon={'qrcode'} />
          </span>
        )}

        <ul className="designer-node-actions">
          <li>
            <a
              onClick={this.moveUp}
              className="action-move-up-node tooltips"
              data-title={this.props.t('designer.node.move.up')}
            >
              <span>
                <IconComponent icon={'arrow-up'} />
              </span>
            </a>
          </li>
          <li>
            <a
              onClick={this.moveDown}
              className="action-move-down-node tooltips"
              data-title={this.props.t('designer.node.move.down')}
            >
              <span>
                <IconComponent icon={'arrow-down'} />
              </span>
            </a>
          </li>
          <li className="ml-4">
            <a
              onClick={this.deleteClick}
              className="action-delete-node tooltips"
              data-title={this.props.t('designer.node.delete.title')}
            >
              <span>
                <IconComponent icon={'times'} />
              </span>
            </a>
          </li>
          <li className="ml-4">
            <a
              onClick={this.editClick}
              className="action-edit-node tooltips"
              data-title={this.props.t('designer.node.edit.title')}
            >
              <span>
                <IconComponent icon={'edit'} />
              </span>
            </a>
          </li>
          <li>
            <a
              onClick={this.copyClick}
              className="action-copy-node tooltips"
              data-title={this.props.t('designer.node.copy.title')}
            >
              <span>
                <IconComponent icon={'copy'} />
              </span>
            </a>
          </li>

          <li>
            <a
              onClick={this.cutClick}
              className="action-cut-node tooltips"
              data-title={this.props.t('designer.node.cut.title')}
            >
              <span>
                <IconComponent icon={'cut'} />
              </span>
            </a>
          </li>

          <li className="ml-4">
            {(!this.props.data.subcomponents ||
              this.props.data.subcomponents.filter((e: any) => e.level !== 'Metric').length < 1) &&
              this.props.data.descriptor.allowedSubcomponents &&
              this.props.data.descriptor.allowedSubcomponents.length > 0 &&
              this.props.data.level !== 'Component' && (
                <a
                  onClick={this.addClick}
                  className="action-add-node tooltips"
                  data-title={this.props.t('designer.node.addChild.title')}
                >
                  <span>
                    <IconComponent icon={'plus-circle'} />
                  </span>
                </a>
              )}
            {this.props.data.descriptor.allowedSubcomponents &&
              this.props.data.descriptor.allowedSubcomponents.length > 0 &&
              this.props.data.subcomponents &&
              this.props.data.subcomponents.filter((e: any) => e.level !== 'Metric').length > 0 && (
                <a onClick={this.rightClick} className="next">
                  <span>
                    <IconComponent icon={'chevron-right'} />
                  </span>
                </a>
              )}
          </li>
        </ul>
        {this.props.parent && this.props.parent !== this.props.root && (
          <div onClick={this.leftClick} className="prev">
            <span>
              <svg width="20" height="40" viewBox="0 0 20 40">
                <path stroke="#000" fill="transparent" d="M20,0 l-20,20 l20,20" />
              </svg>
            </span>
          </div>
        )}

        <div
          className={
            'card-data ' + (this.state.detailsLoading ? 'loading' : '') + (this.state.detailsReady ? ' active' : '')
          }
        >
          <div className="card-data-inner">
            <h4>{this.props.t('treeDetailsTitle' + this.state.showDetailsSection)}</h4>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(TreeNode)
