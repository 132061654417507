import { AnyAction, Dispatch } from 'redux'
import { logoutUser } from '../../../inplant-core-fe/auth'
import { getDateRangeFiltering } from '../../../inplant-core-fe/functions/date'
import FetchWrapper from '../../../inplant-core-fe/functions/fetch-wrapper'

export const APIInflux = () => FetchWrapper.getInstance('influx')

export const fetchLotsConsumedHasError = () => {
  return {
    hasErrored: true,
    isLoading: false,
    type: 'FETCH_LOTSCONSUMED_ERROR',
    data: null,
  }
}

export const fetchLotsConsumedIsRunning = () => {
  return {
    isLoading: true,
    type: 'FETCH_LOTSCONSUMED_REQUEST',
  }
}

export const fetchLotsConsumedSuccess = (data: object) => {
  return {
    data,
    isLoading: false,
    type: 'FETCH_LOTSCONSUMED_SUCCESS',
  }
}

export function fetchPiecesData() {
  return (dispatch: Dispatch<AnyAction>, getState: any) => {
    const { dateFilters } = getState()
    const { start, end } = getDateRangeFiltering(dateFilters.dateStart, dateFilters.dateEnd)

    const query = `SELECT max("measure") FROM "IMEPT5MAVOPWAYSY","IMEPTFPBB5ZHK1IT" WHERE time >= '${start}' AND time < '${end}' group by time(1d)`

    dispatch(fetchLotsConsumedIsRunning())

    return APIInflux().request('/influx/query?q=' + query)
      .then((data: any) => {
        dispatch(fetchLotsConsumedSuccess(data))
        return data
      })
      .catch((error: any) => {
        dispatch(fetchLotsConsumedHasError())
        if (error.name === 'FetchError' && error.statusCode === 401) {
          dispatch(logoutUser())
        }
        throw error
      })
  }
}
