import * as React from 'react'
import * as _ from 'lodash'
import { connect } from 'react-redux'
import {
  fetchProductSheet,
  itemsFetchDataFiltered,
  itemsFetchDataFramesFiltered,
  itemsFetchDownloadDataFiltered,
} from '../../../../redux/actions/service'
import { WithTranslation, withTranslation } from 'react-i18next'
import { ProductionListItem } from '../../../../types/ProductionListItem'
import 'datatables.net'
import 'datatables.net-bs4/js/dataTables.bootstrap4.js'
import '../../../../styles/_index.scss'
import * as moment from 'moment'
import { Moment } from 'moment'
import { isJSON, saveDataAsCSV } from '@mv-submodules/inplant-core-fe/functions'
import Table from '@mv-submodules/inplant-components-fe/ui/components/Table/Table'
import AlarmModal from '@mv-submodules/inplant-production-fe-imel/ui/components/widgets/AlarmsModal/AlarmModal'
import CostsModal from '@mv-submodules/inplant-production-fe-imel/ui/components/widgets/CostsModal/CostsModal'
import DetailsModal from '@mv-submodules/inplant-production-fe-imel/ui/components/widgets/DetailsModal/DetailsModal'

import { format, parseISO } from 'date-fns'
import { Button, ButtonGroupHeader, Filters, PageHeader } from '@mv-submodules/inplant-components-fe'
import Column from '@mv-submodules/inplant-components-fe/ui/components/Grid/Column'
import { FilterComponent, filterType } from '@mv-submodules/inplant-components-fe/ui/components/Filters/Filters'
import { consoleLog } from '../../../../../inplant-components-fe/mvfunctions/logs'
import IconComponent from '../../../../../inplant-components-fe/ui/components/MVIcon/Icon'

const timeRangeFileString = (dateStart: string | null, dateEnd: string | null): string => {
  return (
    (dateStart ? '_' + moment(dateStart).format('YYYY-MM-DD') : '') +
    (dateEnd ? '_' + moment(dateEnd).format('YYYY-MM-DD') : '')
  )
}

// interface used to describe the Medatada object of the response
interface RestResponseMetadata {
  resultset: {
    count: number
    limit: number
    offset: number
  }
  totalPieces: number
  totalScrapsInLine: number
}

interface ProductionStateProps {
  items: ProductionListItem[]
  itemsMeta: RestResponseMetadata // see interface above
  hasErrored: boolean
  isLoading: boolean
  config: {
    disabledActions?: string
    validJsonKeys?: string
    hiddenCounters?: string
    dataSourceFrames?: string
    dataSourceMapping?: string
    lotsSourceMapping?: string
  } | null
}

interface ProductionDispatchProps {
  fetchDataFiltered: Function // function to fetch the items on filters
  fetchDataFramesFiltered: Function // function to fetch the items on filters
  fetchDownloadDataFiltered: Function
}

type NullableAlphaNum = number | string | null

interface ProductionOwnState {
  data: ProductionListItem[]
  dateEnd: null | Moment
  dateStart: null | Moment
  filters: any
  isLoading: boolean
  isFetchingCsv: boolean
  isFetchingProductionSheet?: string
  page: number
  pages: number
  pageSize: number
  search: string
  showAlarmsItem: NullableAlphaNum
  showAlarmsDateStart?: string
  showAlarmsDateEnd?: string
  showCostsItem: NullableAlphaNum
  showCostsLot: NullableAlphaNum
  showDetailsItem: NullableAlphaNum
  showDetailsLot: NullableAlphaNum
  sorted: any[]
  count: number
}

interface ButtonAction {
  id: string
  callback: Function
}

const mapStateToProps = (state: any): ProductionStateProps => {
  return {
    items: state.production.items,
    itemsMeta: state.production.items.metadata || [],
    hasErrored: state.production.hasErrored,
    isLoading: state.production.isLoading,
    config: state.config.production || null,
  }
}

const mapDispatchToProps = (dispatch: Function): ProductionDispatchProps => {
  return {
    fetchDataFiltered: (filters: any) => dispatch(itemsFetchDataFiltered(filters)),
    fetchDataFramesFiltered: (filters: any) => dispatch(itemsFetchDataFramesFiltered(filters)),
    fetchDownloadDataFiltered: (filters: any, useFrames?: boolean) =>
      dispatch(itemsFetchDownloadDataFiltered(filters, useFrames)),
  }
}

type ProductionProps = ProductionStateProps & ProductionDispatchProps & /*ProductionOwnProps &*/ WithTranslation

class ListPageView extends React.Component<ProductionProps, ProductionOwnState> {
  private useFrames = this.props.config?.dataSourceFrames && this.props.config.dataSourceFrames === 'true'
  private dataSourceMapping =
    this.props.config && this.props.config.dataSourceMapping && isJSON(this.props.config.dataSourceMapping)
      ? JSON.parse(this.props.config.dataSourceMapping)
      : []

  constructor(props: ProductionProps) {
    super(props)

    this.state = {
      data: [],
      dateEnd: null,
      dateStart: null,
      filters: {},
      isLoading: false,
      page: 0,
      pages: 0,
      pageSize: 15,
      search: '',
      showAlarmsItem: null,
      showAlarmsDateStart: undefined,
      showAlarmsDateEnd: undefined,
      showCostsItem: null,
      showCostsLot: null,
      showDetailsItem: null,
      showDetailsLot: null,
      sorted: [],
      count: 0,
      isFetchingCsv: false,
    }

    this.openDetailsModal = this.openDetailsModal.bind(this)
    this.closeDetailsModal = this.closeDetailsModal.bind(this)
    this.openAlarmsModal = this.openAlarmsModal.bind(this)
    this.closeAlarmsModal = this.closeAlarmsModal.bind(this)
    this.openCostsModal = this.openCostsModal.bind(this)
    this.closeCostsModal = this.closeCostsModal.bind(this)

    this.getFilteredData = this.getFilteredData.bind(this)
    this.getDownloadData = this.getDownloadData.bind(this)
    this.getProductSheet = this.getProductSheet.bind(this)
    this.onPageChange = this.onPageChange.bind(this)
    this.onPageSizeChange = this.onPageSizeChange.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleDateClear = this.handleDateClear.bind(this)
    this.onSearchChange = this.onSearchChange.bind(this)
  }

  /*public renderNoteIcon( item: ProductionListItem ) {
    if (item.notes && item.notes.length > 0) {
      return (
        <a
          href='#'
          data-container='body'
          data-trigger='hover'
          data-toggle='popover'
          data-placement='top'
          data-content={item.notes}
          >
          <i className="far fa-comment-alt" />
          </a>
          )
        } else {
          return <i className="far fa-comment-alt" />
        }
  }*/

  public render() {
    consoleLog(this.props.config?.dataSourceFrames)
    const { t, isLoading, itemsMeta, hasErrored, config } = this.props
    const totalPieces = itemsMeta.totalPieces
    const totalScrapsInLine = itemsMeta.totalScrapsInLine
    const {
      showDetailsItem,
      showDetailsLot,
      showAlarmsItem,
      showAlarmsDateStart,
      showAlarmsDateEnd,
      showCostsItem,
      showCostsLot,
      isFetchingProductionSheet,
      data,
      pages,
      page,
    } = this.state

    if (hasErrored) {
      return (
        <Column key={Date.now()}>
          <label className={'text-center'}>
            <i className="fas fa-2x fa-exclamation-triangle text-danger" />
          </label>
        </Column>
      )
    }

    const actions = [
      {
        id: 'alarms-modal',
        callback: this.openAlarmsModal,
      },
      {
        id: 'costs-modal',
        callback: this.openCostsModal,
      },
      {
        id: 'details-modal',
        callback: this.openDetailsModal,
      },
      {
        id: 'product-sheet',
        callback: this.getProductSheet,
      },
    ]

    const disabledActions =
      config && config.disabledActions && isJSON(config.disabledActions) ? JSON.parse(config.disabledActions) : []
    const dataSourceMapping =
      config && config.dataSourceMapping && isJSON(config.dataSourceMapping) ? JSON.parse(config.dataSourceMapping) : []
    const lotsSourceMapping =
      config && config.lotsSourceMapping && isJSON(config.lotsSourceMapping) ? JSON.parse(config.lotsSourceMapping) : []

    const validActions = actions.reduce((acc: ButtonAction[], action: ButtonAction) => {
      if (!disabledActions.includes(action.id)) {
        return [...acc, action]
      }
      return acc
    }, [])

    const hiddenCounters =
      config && config.hiddenCounters && isJSON(config.hiddenCounters) ? JSON.parse(config.hiddenCounters) : []

    const columns = this.useFrames
      ? dataSourceMapping.map(
          (column: { label: string; id: string; time?: boolean; sortable?: boolean; width?: number }) => {
            return {
              Header: column.label ? column.label : t(`production.labels.${column.id}`, { defaultValue: column.id }),
              accessor: column.id,
              sortable: column.sortable,
              maxWidth: column.width,
              Cell: (props: any) => {
                return column.time && props.original[column.id]
                  ? moment(props.original[column.id]).format('YYYY-MM-DD HH:mm:ss')
                  : props.original[column.id]
              },
            }
          }
        )
      : lotsSourceMapping.length !== 0
      ? lotsSourceMapping.map(
          (column: { label: string; id: string; time?: boolean; sortable?: boolean; width?: number }) => {
            return {
              Header: column.label ? column.label : t(`production.labels.${column.id}`, { defaultValue: column.id }),
              accessor: column.id,
              sortable: column.sortable,
              maxWidth: column.width,
              Cell: (props: any) => {
                return column.time && props.original[column.id]
                  ? moment(props.original[column.id]).format('YYYY-MM-DD HH:mm:ss')
                  : props.original[column.id]
              },
            }
          }
        )
      : [
          {
            Header: t('production.labels.startTimeHours'),
            id: 'startTimeHours',
            Cell: (props: any) => moment(props.original.startTime).format('YYYY-MM-DD HH:mm:ss'),
            sortable: false,
          },
          {
            Header: t('production.labels.endTime'),
            id: 'endTime',
            Cell: (props: any) => moment(props.original.endTime).format('YYYY-MM-DD HH:mm:ss'),
            sortable: false,
          },
          {
            Header: t('production.labels.pieces'),
            accessor: 'pieces',
          },
          {
            Header: t('production.labels.lot'),
            accessor: 'serial',
          },
        ]

    const filters: FilterComponent[] = [
      {
        type: 'SearchInput' as filterType,
        label: 'Search',
        name: 'search',
        onChange: (name: string, value: string | number | null) =>
          this.onSearchChange((value && (value as string)) || ''),
        throttle: 500,
        value: this.state.search,
      },
      {
        type: 'DateRange' as filterType,
        label: t('dateRangeFiler.pleaseSelect'),
        value: null,
        selectedValues:
          (this.state.dateStart &&
            this.state.dateEnd && [
              this.state.dateStart.format('YYYY-MM-DD'),
              this.state.dateEnd.format('YYYY-MM-DD'),
            ]) ||
          [],
        onDateSelectedChange: (selected: string[]) => {
          this.setState(
            {
              dateStart: (selected[0] && moment(selected[0])) || null,
              dateEnd: (selected[1] && moment(selected[1])) || null,
            },
            this.getFilteredData
          )
        },
      },
    ]

    return (
      <React.Fragment>
        {showDetailsItem && (
          <DetailsModal item={showDetailsItem} lotId={showDetailsLot} closeModalFn={this.closeDetailsModal} />
        )}
        {showAlarmsItem && (
          <AlarmModal
            item={showAlarmsItem}
            lotId={showDetailsLot}
            closeModalFn={this.closeAlarmsModal}
            dateStart={showAlarmsDateStart}
            dateEnd={showAlarmsDateEnd}
          />
        )}
        {showCostsItem && <CostsModal item={showCostsItem} lotId={showCostsLot} closeModalFn={this.closeCostsModal} />}

        <PageHeader
          title={t('production.list.title')}
          rightButtons={
            <ButtonGroupHeader>
              <Button
                variant="primary-alternate"
                icon={'download'}
                onClick={this.getDownloadData}
                isLoading={this.state.isFetchingCsv}
                label={this.props.t('production.download.buttonLabel')}
              />
            </ButtonGroupHeader>
          }
        />

        <div className="inplant-fe-production">
          <div className="content">
            <Filters fields={filters} />

            {!this.useFrames && (
              <dl className="row containerSumPiecesFromRange">
                {!hiddenCounters.includes('totalPieces') && (
                  <>
                    <Column sm={2}>{t('production.table.summary.totalPieces')}</Column>
                    <Column sm={10}>{totalPieces}</Column>
                  </>
                )}
                {!hiddenCounters.includes('scrapsInLine') && (
                  <>
                    <Column sm={2}>{t('production.table.summary.scrapsInLine')}</Column>
                    <Column sm={10}>{totalScrapsInLine}</Column>
                  </>
                )}
              </dl>
            )}

            <Table
              className={'-striped -highlight'}
              columns={[
                ...columns,
                ...[
                  {
                    Header: '',
                    sortable: false,
                    maxWidth: 150,
                    Cell: (props: any) => (
                      <div className="btn-group text-right" role="group">
                        <button
                          type="button"
                          className="btn btn-secondary dropdown-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          disabled={props.original.serial === isFetchingProductionSheet}
                        >
                          {props.original.serial === isFetchingProductionSheet ? (
                            <IconComponent icon={'circle-notch'} spin={true} />
                          ) : (
                            t('production.list.buttonLabel')
                          )}
                        </button>
                        <div className="dropdown-menu">
                          {validActions.map((action: ButtonAction) => (
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => {
                                action.callback(props.original)
                              }}
                              key={action.id}
                            >
                              {t('production.list.actions.' + action.id)}
                            </a>
                          ))}
                        </div>
                      </div>
                    ),
                    className: 'align-contents-end text-right',
                  },
                ],
              ]}
              isFetching={isLoading}
              manual={true}
              pageText={t('production.table.general.page')}
              noDataText={'no data'}
              ofText={t('production.table.summary.of')}
              rowsText={t('production.table.summary.rows')}
              data={data}
              pages={pages}
              page={page}
              onFetchData={this.getFilteredData}
              onPageChange={this.onPageChange}
              onPageSizeChange={this.onPageSizeChange}
              defaultSorted={[
                {
                  id: 'startTime',
                  desc: true,
                },
              ]}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }

  private onSearchChange(search: string) {
    const filters = this.state.filters
    filters.page = 0
    this.setState({ search, filters, page: 0 }, () => {
      this.getFilteredData()
    })
  }

  // method to handle change of page in table
  private onPageChange(page: number) {
    const filters = this.state.filters
    filters.page = page >= 0 ? page : 0
    // filters.pageSize = this.state.pageSize
    this.setState({ page: page }) // tslint:disable-line
    this.getFilteredData(filters)
  }

  // method to handle change of rows of the page
  private onPageSizeChange(pageSize: number, index: number) {
    this.setState({ pageSize, page: index })
    const filters = this.state.filters
    filters.page = index
    filters.pageSize = pageSize
    this.getFilteredData(filters)
  }

  private getFilteredData(filters?: any, instance?: any, fields?: string) {
    if (!this.state.isLoading) {
      let newState = {}
      if (filters) {
        newState = { isLoading: true, filters }
      } else {
        newState = { isLoading: true }
      }

      this.setState(newState, () => {
        ;(this.useFrames
          ? this.props.fetchDataFramesFiltered({
              limit: this.state.filters.pageSize,
              offset: this.state.filters.page * this.state.filters.pageSize,
              q: this.state.search,
              orderByKey:
                this.state.filters.sorted && this.state.filters.sorted.length > 0
                  ? this.state.filters.sorted[0].id
                  : null,
              direction:
                this.state.filters.sorted && this.state.filters.sorted.length > 0
                  ? this.state.filters.sorted[0].desc
                    ? 'desc'
                    : 'asc'
                  : null,
              dateStart: this.state.dateStart && this.state.dateStart.format('YYYY-MM-DD 00:00:00'),
              dateEnd: this.state.dateEnd && this.state.dateEnd.format('YYYY-MM-DD 23:59:59'),
            })
          : this.props.fetchDataFiltered({
              limit: this.state.filters.pageSize,
              offset: this.state.filters.page * this.state.filters.pageSize,
              q: this.state.search,
              orderByKey:
                this.state.filters.sorted && this.state.filters.sorted.length > 0
                  ? this.state.filters.sorted[0].id
                  : null,
              direction:
                this.state.filters.sorted && this.state.filters.sorted.length > 0
                  ? this.state.filters.sorted[0].desc
                    ? 'desc'
                    : 'asc'
                  : null,
              fields: fields || null,
              dateStart: this.state.dateStart && this.state.dateStart.format('YYYY-MM-DD 00:00:00'),
              dateEnd: this.state.dateEnd && this.state.dateEnd.format('YYYY-MM-DD 23:59:59'),
            })
        )
          .then((res: any) => {
            if (res.items && res.items.metadata && res.items.metadata.resultset) {
              const { count, limit } = res.items.metadata.resultset

              this.setState({
                data: res.items.results,
                pages: Math.ceil(count / limit),
                isLoading: false,
                count,
              })
            }
          })
          .catch((error: any) => {
            this.setState({
              isLoading: false,
            })
            console.log(error) // tslint:disable-line
          })
      })
    }
  }

  private openDetailsModal(item: ProductionListItem) {
    this.setState({
      showDetailsItem: item.uuid,
      showDetailsLot: item.serial,
    })
  }

  private getProductSheet(item: ProductionListItem) {
    if (!this.state.isFetchingProductionSheet) {
      this.setState({ isFetchingProductionSheet: item.serial })
      fetchProductSheet(item.uuid)
        .then((data: any) => {
          const objectURL = URL.createObjectURL(data)
          const link = document.createElement('a')
          const date = format(parseISO(item.startTime), 'yyyy-MM-dd HH:mm:ss')
          const title = item.serial
          link.href = objectURL
          link.download = `${date} - ${title}.pdf`
          link.style.display = 'none'
          document.body.appendChild(link)
          link.click()
          link.remove()
        })
        .catch((e: any) => {
          console.log(e) //tslint:disable-line
        })
        .finally(() => {
          this.setState({ isFetchingProductionSheet: undefined })
        })
    }
  }

  private closeDetailsModal() {
    this.setState({
      showDetailsItem: null,
      showDetailsLot: null,
    })
  }

  private openAlarmsModal(item: ProductionListItem) {
    this.setState({
      showAlarmsItem: item.uuid,
      showDetailsLot: item.serial,
      showAlarmsDateStart:
        item.download_time && item.process_time
          ? moment(item.download_time)
              .subtract(moment.duration(item.process_time))
              .format('YYYY-MM-DD HH:mm:ss')
          : undefined,
      showAlarmsDateEnd: item.download_time ? moment(item.download_time).format('YYYY-MM-DD HH:mm:ss') : undefined,
    })
  }

  private closeAlarmsModal() {
    this.setState({
      showAlarmsItem: null,
      showDetailsLot: null,
      showAlarmsDateStart: undefined,
      showAlarmsDateEnd: undefined,
    })
  }

  private openCostsModal(item: ProductionListItem) {
    this.setState({
      showCostsItem: item.uuid,
      showCostsLot: item.serial,
    })
  }

  private closeCostsModal() {
    this.setState({
      showCostsItem: null,
      showCostsLot: null,
    })
  }

  private handleDateChange(
    e: React.FormEvent<HTMLSelectElement>,
    data: { chosenLabel: string; startDate: moment.Moment; endDate: moment.Moment }
  ) {
    this.setState(
      {
        dateStart: data.startDate,
        dateEnd: data.endDate,
        page: 0,
      },
      () => {
        this.getFilteredData()
      }
    )
  }

  private handleDateClear(e: React.FormEvent<HTMLSelectElement>, picker: any) {
    picker.element.val('')
    this.setState(
      {
        dateStart: null,
        dateEnd: null,
        page: 0,
      },
      () => {
        this.getFilteredData()
      }
    )
  }

  private async getDownloadData() {
    if (
      !this.state.isFetchingCsv &&
      ((this.state.dateStart && this.state.dateEnd) ||
        confirm(this.props.t('production.confirm.downloadWithNoDateRangeSelected')))
    ) {
      this.setState({ isFetchingCsv: true })
      this.props
        .fetchDownloadDataFiltered(
          {
            limit: this.state.count + 10,
            q: this.state.search,
            dateStart: this.state.dateStart && this.state.dateStart.format('YYYY-MM-DD 00:00:00'),
            dateEnd: this.state.dateEnd && this.state.dateEnd.format('YYYY-MM-DD 23:59:59'),
          },
          this.useFrames
        )
        .then((res: any) => {
          if (res.items && res.items.metadata && res.items.metadata.resultset) {
            const validJsonKeys =
              this.props.config && this.props.config.validJsonKeys && isJSON(this.props.config.validJsonKeys)
                ? JSON.parse(this.props.config.validJsonKeys)
                : []

            const data = res.items.results

            let downloadDataSrc
            let downloadData

            if (data && data.length > 0) {
              downloadDataSrc = data.map((lot: ProductionListItem) => {
                let lotData: string[] = []

                if (this.useFrames) {
                  this.dataSourceMapping.map(
                    (column: { label: string; id: string; time?: boolean; sortable?: boolean }) => {
                      lotData.push(lot[column.id])
                    }
                  )
                } else {
                  lotData = [
                    String(lot.id),
                    String(lot.serial),
                    String(lot.pieces),
                    String(lot.scrapsInLine),
                    String(lot.startTime),
                    String(lot.endTime),
                  ]
                }

                if (lot.jsonData) {
                  const jsonData = JSON.parse(lot.jsonData)
                  const keys = Object.keys(jsonData)

                  for (const key in validJsonKeys) {
                    if (keys.includes(validJsonKeys[key])) {
                      lotData.push(String(jsonData[validJsonKeys[key]]))
                    } else {
                      lotData.push('')
                    }
                  }
                }

                return lotData
              })

              const header = this.useFrames
                ? this.dataSourceMapping.map(
                    (column: { label: string; id: string; time?: boolean; sortable?: boolean }) => {
                      return column.label
                        ? column.label
                        : this.props.t(`production.labels.${column.id}`, { defaultValue: column.id })
                    }
                  )
                : ['Code', 'Lot', 'Pieces', 'Scraps In Line', 'Time Load First', 'Time Download Last']

              downloadData = [header.concat(validJsonKeys)].concat(downloadDataSrc)

              saveDataAsCSV(
                downloadData,
                'production_lots_' +
                  timeRangeFileString(
                    this.state.dateStart && this.state.dateStart.format('YYYY-MM-DD 00:00:00'),
                    this.state.dateEnd && this.state.dateEnd.format('YYYY-MM-DD 23:59:59')
                  ),
                'csv',
                false
              )
            }
          }
        })
        .finally(() => {
          this.setState({ isFetchingCsv: false })
        })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ListPageView))
