const it = {
  designer: {
    plantDesigner: 'Plant Designer',
    number: 'Numero',
    notification: {
      success: 'Successo',
      error: 'Errore',
      info: 'Info',
      warning: 'Attenzione',
    },
    copy: {
      success: 'Copiato negli appunti!',
      click: 'Clicca per copiare negli appunti',
    },
    plant: {
      metrics: 'Metriche Globali',
      attachments: 'Documenti Globali',
    },
    values: {
      true: 'Vero',
      false: 'Falso',
    },
    response: {
      saved: 'Modello correttamente salvato.',
    },
    media: {
      types: {
        Manual: 'Manuale',
        Accessories: 'Accessori',
        TechnicalFile: 'File Tecnici',
        SpareParts: 'Ricambi',
        Certification: 'Certificazioni',
      },
      selectType: 'Seleziona tipo',
      clickToEdit: 'Clicca per modificare',
      translationModal: {
        title: 'Traduzioni allegato',
        save: 'Salva',
        close: 'Chiudi',
      },
      hasLabelOrTranslation: 'Nome modificato/tradotto',
      placeholder: {
        version: 'versione'
      }
    },
    module: 'Designer',
    fetching: 'Caricamento',
    i18n: {
      select: 'Generico',
    },
    fileBrowser: {
      modal: {
        title: 'Aggiungi file',
        cancel: 'Annulla',
        select: 'Seleziona file',
        error: 'Errore caricando i file',
      },
    },
    mediaPreview: {
      modal: {
        title: 'Aggiungi Video YouTube',
        cancel: 'Annulla',
        select: 'Aggiungi',
        error: 'Errore di caricamento',
        placeholder: {
          url: 'URL del video',
          title: 'titolo per il video',
        },
        previewTitle: 'Anteprima',
      },
      configureYouTubeKey: 'Verifica la chiave YouTube API',
    },
    modal: {
      search: 'Cerca file...',
    },
    node: {
      details: 'Dettagli',
      metrics: 'Metriche',
      metric: {
        add: 'Aggiungi metrica',
        edit: 'Modifica metrica',
        remove: 'Elimina metrica',
        cancel: 'Annulla',
        save: 'Salva',
        saveOrder: "Salva l'ordinamento",
        resetOrder: 'Ripristina',
        askSave: "Salvare l'ordinamento prima di aggiungere metriche",
        previewAction: 'Clicca per visualizzazione gli ultimi dati',
        modal: {
          title: 'Anteprima dati (ultimi 100 valori)',
          titleSeparator: ' anteprima (ultimi 100 valori) ',
          close: 'Chiudi',
          noData: 'Non ci sono dati disponibili',
        },
      },
      attachments: 'Documenti',
      media: 'Media',
      edit: {
        title: 'Modifica nodo',
        submit: 'Salva',
        delete: 'Elimina',
        success: 'Nodo salvato',
        notFoundDetails: 'Dettagli nodo non trovati',
        notFound: 'Nodo non trovato',
      },
      add: {
        title: 'Crea nodo',
        submit: 'Crea',
      },
      addChild: {
        title: 'Crea sotto nodo',
        submit: 'Crea',
        success: 'Nodo aggiunto con successo',
        notFoundDetails: 'Dettagli nodo non trovati',
        notFound: 'Nodo non trovato',
      },
      delete: {
        title: 'Elimina nodo',
      },
      copy: {
        title: 'Copia nodo',
      },
      cut: {
        title: 'Taglia nodo',
      },
      close: 'Annulla',
      move: {
        up: 'Sposta su',
        down: 'Sposta giù',
      },
      attributes: {
        hasTranslations: 'Traduzioni presenti',
        hasQRCode: 'QR Code applicato',
        plant: {
          id: 'ID',
          label: 'Etichetta',
          pid: 'P&D',
          fpid: 'P&D Padre',
          dataBlock: 'Data Block',
          polling: 'Pooling',
          type: 'Type',
          nodeType: 'Node Type',
        },
        macroarea: {
          id: 'ID',
          label: 'Etichetta',
          pid: 'P&D',
          fpid: 'P&D Padre',
          dataBlock: 'Data Block',
          polling: 'Pooling',
          type: 'Type',
          nodeType: 'Node Type',
          hasQRCode: 'QR Code',
        },
        section: {
          id: 'ID',
          label: 'Etichetta',
          pid: 'P&D',
          fpid: 'P&D Padre',
          dataBlock: 'Data Block',
          polling: 'Pooling',
          type: 'Type',
          nodeType: 'Node Type',
          hasQRCode: 'QR Code',
        },
        componentGroup: {
          id: 'ID',
          label: 'Etichetta',
          pid: 'P&D',
          fpid: 'P&D Padre',
          dataBlock: 'Data Block',
          polling: 'Pooling',
          type: 'Type',
          nodeType: 'Node Type',
          hasQRCode: 'QR Code',
        },
        component: {
          id: 'ID',
          label: 'Etichetta',
          pid: 'P&D',
          fpid: 'P&D Padre',
          dataBlock: 'Data Block',
          polling: 'Pooling',
          type: 'Type',
          nodeType: 'Node Type',
          hasQRCode: 'QR Code',
        },
        metric: {
          id: 'ID',
          label: 'Etichetta',
          pid: 'P&D',
          fpid: 'P&D Padre',
          dataBlock: 'Data Block',
          memoryAddress: 'Data Block',
          polling: 'Pooling',
          type: 'Type',
          nodeType: 'Node Type',
          savingMode: 'Saving Mode',
          dataType: 'Data Type',
        },
        node: {
          id: 'ID',
          label: 'Etichetta',
          pid: 'P&D',
          fpid: 'P&D Padre',
          dataBlock: 'Data Block',
          polling: 'Pooling',
          type: 'Type',
          nodeType: 'Node Type',
          hasQRCode: 'QR Code',
        },
      },
      template: {
        select: 'Template',
        default: 'Seleziona...',
      },
    },
    confirm: {
      delete: 'Vuoi davvero eliminare il nodo?',
      resetModel: 'Vuoi davvero resettare il modello?',
      saveModel:
        "ATTENZIONE!!!\n\nSei sicuro di voler salvare il modello?\n\nIl nuovo modello di impianto sarà immediatamente utilizzato da tutta tutta l'applicazione.",
      removeAttachment: "Vuoi davvero rimuovere l'allegato?",
      removeMedia: 'Vuoi davvero rimuovere il video?',
      removeMetric: 'Vuoi davvero rimuovere la metrica?',
      reorderMetrics: 'Vuoi davvero salvare il nuovo ordinamento delle metriche?',
      leave: 'ATTENZIONE!!!\n\nIl modello impianto è cambiato, vuoi davvero abbandonare le modifiche?',
    },
    errors: {
      unique: 'deve essere unico',
      download: 'Errore scaricando il file',
      invalidFPD: 'P&D Padre inesistente',
      IDexists: 'ID già esistente',
      formHasErrors: 'Ci sono errori, si prega di verificare i dati inseriti',
    },
    global: {
      actions: {
        upload: 'Carica',
        download: 'Scarica',
        downloadFull: 'Scarica Completo',
        resetModel: 'Reimposta',
        save: 'Salva',
        copiedNode: 'Copiato',
        cuttedNode: 'Tagliato',
        export: 'Esporta',
      },
      exports: {
        hasQRCode: 'QR Code',
      },
    },
  },
  treeDetailsFilename: 'Nome',
  treeDetailsVersion: 'Versione',
  treeDetailsDate: 'Data',
  treeDetailsTitlenull: '',
  treeDetailsTitlemanuals: 'Manuali',
  treeDetailsTitlecertificates: 'Certificati',
  treeDetailsTitledataSheets: 'Schede tecniche',
  treeDetailsTitlespareParts: 'Ricambi',
  treeDetailsCode: 'Codice',
  treeDetailscode: 'Codice',
  treeDetailsDescription: 'Descrizione',
  treeDetailsPrice: 'Prezzo',
  treeDetailsprice: 'Prezzo',
  treeDetailsTitlesparePartsList: 'Esploso ricambi',
  treeDetailsTitlemaintenances: 'Prossime Manutenzioni',
  treeDetailsoperation: 'Operazione',
  treeDetailsdescription: 'Descrizione',
  treeDetailsexpected: 'Prevista',
  treeDetailsdocumentType: 'Tipologia',
  treeDetailslink: 'Link',
  treeDetailslastEdit: 'Data',
}

export default it
