import { NodeType } from '../types'
import * as Metrics from '../types/metrics'
import { MetricTemplate, MetricValues } from '../types/template'
import { Component, ComponentGroup, MacroArea, Metric, Plant, Section, SectionGroup } from '../types/templates'
import { memoryFromAddress } from './shared'
import { NodeMetricType } from '../types/designer'
import { PLANT_PREFIX } from '../ui/components/views/DesignerComponent/DesignerComponentPageView'

export const availableTemplates = [Plant, MacroArea, Section, SectionGroup, ComponentGroup, Component, Metric]

export let usedNodeIds: string[] = []

export const getDepth = (obj: any) => {
  let depth = 0
  if (obj.subcomponents) {
    obj.subcomponents.forEach((d: any) => {
      const tmpDepth = getDepth(d)
      if (tmpDepth > depth) {
        depth = tmpDepth
      }
    })
  }
  return 1 + depth
}

export const getPath = (object: any, search: string) => {
  const parts = search.split(':')
  const key = parts[0]
  const value = parts[1]
  let path = ''

  function iter(o: any, p: any): any {
    return Object.keys(o).some((k: any) => {
      if (k === key && o[k] === value) {
        path = p.concat(o[k]).join('.')
        return true
      }
      if (o[k] !== null && typeof o[k] === 'object') {
        return iter(
          o[k],
          k === 'subcomponents' && !o.data.id ? p : k === 'subcomponents' && o.data.id ? p.concat(o.data.id) : p
        )
      }
    })
  }

  iter(object, [])
  return path
}

export const buildColumnsData = (data: any, element: string, startPath: string = '') => {
  if (startPath === '') {
    startPath = getPath(data, 'id:' + element) || ''
  }
  const columns: any = []
  const root = data.data.id
  if (data && data.subcomponents) {
    data.subcomponents
      .filter((e: any) => e.level !== 'Metric')
      .forEach((e: any) => {
        e.path = root + '.' + e.data.id
      })
  }

  data.path = root

  function recurse(datas: any, keys: any) {
    let key: any = ''

    if (keys.length === 1) {
      const dataArray = datas.subcomponents.filter((ele: any) => {
        return ele.level !== 'Metric' && ele.data.id === keys[0] // ele.level !== "Metric" &&
      })

      if (
        dataArray.length > 0 &&
        dataArray[0].subcomponents.length > 0 // &&
        // dataArray[0].subcomponents.filter(( subcomponent: any ) => subcomponent.level !== "Metric").length > 0
      ) {
        columns.push(dataArray[0])
      }
    } else {
      key = keys.shift()
      if (
        datas.subcomponents.length > 0 &&
        key !== '' // &&
        // datas.subcomponents.filter(( subcomponent: any ) => subcomponent.level !== "Metric").length > 0
      ) {
        datas.subcomponents
          // .filter(( subcomponent: any ) => subcomponent.level !== "Metric")
          .forEach((component: any) => {
            if (component.data && component.data.id === key) {
              columns.push(component)
              recurse(component, keys)
            }
          })
      }
    }
  }

  columns.push(data)

  if (startPath && element !== root) {
    const path = startPath.replace(root + '.', '')
    recurse(data, path.split('.'))
  }

  return columns
}

export const updateDataNode = (path: string, data: any, node: any): any => {
  if (data.path && data.path === path) {
    if (data.level !== 'Metric') {
      const hasChangedType = data.data.nodeType !== node.data.nodeType

      data.level = node.level // @todo use descriptor and common attributes
      data.data.documents = node.data.documents || []
      data.data.media = node.data.media || []
      data.data.id = node.data.id
      data.data.label = node.data.label
      data.data.pid = node.data.pid
      data.data.fpid = node.data.fpid
      data.data.dataBlock = node.data.dataBlock
      data.data.nodeType = node.data.nodeType
      data.data.hasQRCode = node.data.hasQRCode
      data.data.translations = node.data.translations
      data.data.tags = node.data.tags || []
      if (node.subcomponents) {
        data.subcomponents = node.subcomponents
      }

      const multiple = Object.keys(node.data).filter((mm: any) => mm.search(/^i4P_/) > -1)
      if (multiple && multiple.length > 0) {
        multiple.forEach((name: string) => (data.data[name] = node.data[name]))
      }

      if (data.descriptor && node.descriptor && data.descriptor.id !== node.descriptor.id) {
        data.subcomponents = []
        data.descriptor = null
      }

      if (hasChangedType) {
        data.subcomponents = []
      }
    }
  }

  if (data.subcomponents) {
    data.subcomponents.forEach((e: any) => updateDataNode(path, e, node))
  }
}

export const findNodeByPath = (path: string, currentNode: any): NodeType | false => {
  let i
  let currentChild
  let result

  if (path === currentNode.path) {
    return currentNode
  } else {
    if (currentNode.subcomponents) {
      for (i = 0; i < currentNode.subcomponents.length; i += 1) {
        currentChild = currentNode.subcomponents[i]

        result = findNodeByPath(path, currentChild)
        if (result !== false) {
          // @ts-ignore
          return result
        }
      }
    }

    return false
  }
}

export const findPD = (fpid: string, currentNode: any): NodeType | boolean => {
  let i
  let currentChild
  let result

  if (currentNode.data && fpid === currentNode.data.pid) {
    return currentNode
  } else {
    if (currentNode.subcomponents) {
      for (i = 0; i < currentNode.subcomponents.length; i += 1) {
        currentChild = currentNode.subcomponents[i]

        result = findPD(fpid, currentChild)
        if (result !== false) {
          // @ts-ignore
          return result
        }
      }
    }

    return false
  }
}

export const findAttribute = (value: string, attribute: string, currentNode: any): NodeType | boolean => {
  let i
  let currentChild
  let result

  if (currentNode.data && value === currentNode.data[attribute]) {
    return currentNode
  } else {
    if (currentNode.subcomponents) {
      for (i = 0; i < currentNode.subcomponents.length; i += 1) {
        currentChild = currentNode.subcomponents[i]

        result = findAttribute(value, attribute, currentChild)
        if (result !== false) {
          // @ts-ignore
          return result
        }
      }
    }

    return false
  }
}

export const deleteNode = (path: string, data: any): void => {
  if (data.subcomponents && data.subcomponents.length > 0) {
    const ind = data.subcomponents.findIndex((el: any) => {
      return el && el.path === path
    })

    if (ind !== -1) {
      data.subcomponents.splice(ind, 1)
    }

    if (data.subcomponents && data.subcomponents.length > 0) {
      data.subcomponents.forEach((e: any) => {
        deleteNode(path, e)
      })
    }
  }
}

export const validPath = (pathToCheck: string, dataStructure: any): string => {
  if (!pathToCheck || !dataStructure || !dataStructure.content) {
    return ''
  }

  const recursiveCheck = (path: string[], data: any, out: string[]) => {
    if (path.length > 0) {
      const id = path[0]

      if (
        id &&
        data.data &&
        data.data.id &&
        id === data.data.id &&
        data.subcomponents &&
        data.subcomponents.filter((e: any) => e.level !== 'Metric').length > 0
      ) {
        out.push(id)
        data.subcomponents.forEach((e: any) => {
          return recursiveCheck(path.slice(1), e, out)
        })
      }
    }

    return out
  }
  const output = recursiveCheck(pathToCheck.split('.'), dataStructure.content, [])

  return output.join('.')
}

export const hydrateNodes = (data: NodeType): void => {
  if (!data.descriptor) {
    const descriptor = availableTemplates.find(t => t.name === data.level)
    if (descriptor) {
      data.descriptor = descriptor
      data.meta = {
        currentMemory: null,
      }
    }
  }

  if (data.level !== 'Metric') {
    if (!data.data.documents) {
      data.data.documents = []
    }

    if (!data.path) {
      data.path = data.data.id
    }
  }

  if (data.subcomponents && data.subcomponents.length > 0) {
    data.subcomponents.forEach((e: any) => {
      if (e.level !== 'Metric') {
        e.path = data.path + '.' + e.data.id
      }
      hydrateNodes(e)
    })
  }
}

export const orderSubcomponents = (data: NodeType): void => {
  if (data.subcomponents && data.subcomponents.length > 0) {
    data.subcomponents = data.subcomponents
      .filter((n: NodeType | MetricTemplate) => n.level === 'Metric')
      .concat(data.subcomponents.filter((n: NodeType | MetricTemplate) => n.level !== 'Metric'))

    data.subcomponents.forEach((e: NodeType | MetricTemplate) => {
      orderSubcomponents(e as NodeType)
    })
  }
}

export const cleanNodes = (data: Partial<NodeType>): void => {
  if (data.descriptor) {
    const validKeys = data.descriptor.fields.map(f => f.name)
    if (data.data && validKeys) {
      Object.keys(data.data).forEach((key: string) => {
        if (
          !validKeys.includes(key) &&
          key !== 'documents' &&
          key !== 'translations' &&
          key !== 'id' &&
          key !== 'media' &&
          key !== 'tags'
        ) {
          delete data.data[key]
        }
      })

      if (!data.data.fpid) {
        data.data.fpid = ''
      }

      if (!data.data.pid) {
        data.data.pid = ''
      }

      if (!data.data.nodeType) {
        data.data.nodeType = data.level === 'Component' ? null : 'i4P_NoNodeType'
      }

      if (!data.data.dataBlock) {
        data.data.dataBlock = ''
      }

      if (!data.data.dataType) {
        data.data.dataType = ''
      }

      if (!data.data.translations) {
        data.data.translations = {}
      }

      if (!data.data.documents) {
        data.data.documents = []
      }

      if (!data.data.media) {
        data.data.media = []
      }

      if (!data.data.tags) {
        data.data.tags = []
      }
    }
    delete data.descriptor
  }

  if (data.level === 'Metric') {
    delete data.meta
    delete data.memory
    delete data.dataType
  }

  if (data.meta) {
    delete data.meta
  }

  if (data.subcomponents && data.subcomponents.length > 0) {
    data.subcomponents = data.subcomponents.filter(
      (e: any) => !(e.level === 'Metric' && (e.type === 'Label' || e.type === 'Type'))
    )

    data.subcomponents.forEach((e: any) => {
      cleanNodes(e)
    })
  }
}

export const hydrateNodesMeasures = (data: any): void => {
  if (
    data.level === 'Component' &&
    data.data.nodeType &&
    data.data.nodeType !== '' &&
    data.data.nodeType !== 'i4P_NoNodeType'
  ) {
    // electric component, (re)populate from template
    const componentMetric = Metrics.default[data.data.nodeType]
    if (componentMetric) {
      // inside component, only metrics
      const oldMetrics = JSON.parse(JSON.stringify(data.subcomponents))
      data.subcomponents = []

      componentMetric.metrics.forEach((m: any) => {
        if (!m.hasOwnProperty('multiple')) {
          const oldMetric: MetricTemplate | null = oldMetrics.find(
            (e: MetricTemplate) => e.type === m.metric.type && (e.label.endsWith(m.suffix) || e.label === m.label)
          )

          if (oldMetric) {
            // update old metric
            data.subcomponents.push({
              id: oldMetric.id,
              label: oldMetric.label,
              type: m.metric.type,
              memoryAddress: '',
              memory: m.memory,
              polling: m.polling === true || m.polling === 'true',
              level: 'Metric',
              translations: {},
              savingMode: m.savingMode || 'Other',
              tags: m.tags
            })
          } else {
            // old metric doesnt exits, create
            data.subcomponents.push({
              id: data.data.id + m.suffix,
              label: m.metric.label && m.metric.label !== '' ? m.metric.label : data.data.id + m.suffix,
              type: m.metric.type,
              memoryAddress: '',
              memory: m.memory,
              polling: m.polling === true || m.polling === 'true',
              level: 'Metric',
              translations: {},
              savingMode: m.savingMode || 'Other',
              tags: []
            })
          }
        } else {
          const componentMetricMulti = Metrics.default[m.metricType.name]
          if (componentMetricMulti) {
            const selectedNumber = parseInt(data.data[m.metricType.name], 10) || 1
            const repeatNumber = m.hasOwnProperty('fixed') ? m.fixed : selectedNumber

            for (let ii = 1; ii <= repeatNumber; ii++) {
              componentMetricMulti.metrics.forEach((mm: MetricValues) => {
                const oldMetric: MetricTemplate | null = oldMetrics.find(
                  (e: MetricTemplate) =>
                    e.type === mm.metric.type &&
                    e.label.match(new RegExp('_' + mm.suffix.replace('_', '') + '_' + ii + '_([1-9]d*)$'))
                )

                if (oldMetric) {
                  // old metric, update
                  data.subcomponents.push({
                    id: oldMetric.id,
                    label: oldMetric.label,
                    type: mm.metric.type,
                    memoryAddress: '',
                    memory: mm.memory,
                    polling: mm.polling === true || mm.polling === 'true',
                    level: 'Metric',
                    translations: {},
                    savingMode: mm.savingMode || 'Other',
                    tags: mm.tags
                  })
                } else {
                  // new metric, create unique
                  data.subcomponents.push({
                    id: data.data.id + mm.suffix + '_' + ii + '_' + repeatNumber,
                    label: data.data.id + mm.suffix + '_' + ii + '_' + repeatNumber,
                    type: mm.metric.type,
                    memoryAddress: '',
                    memory: mm.memory,
                    polling: mm.polling === true || mm.polling === 'true',
                    level: 'Metric',
                    translations: {},
                    savingMode: mm.savingMode || 'Other',
                    tags: []
                  })
                }
              })
            }
          }
        }
      })
    }
  } else if (data.level !== 'Metric') {
    // not electric Component level, with childs
    const metrics = data.subcomponents.filter((e: NodeType) => e.level === 'Metric')

    if (metrics.length > 0) {
      const metricSubcomponents: any = []

      data.subcomponents.forEach((m: NodeMetricType) => {
        if (m.level === 'Metric') {
          let memory

          if (m.memoryAddress && m.memoryAddress !== '' && !m.memory) {
            memory = memoryFromAddress(m.memoryAddress)
          }

          metricSubcomponents.push({
            id: m.id,
            label: m.label,
            type: m.type,
            memoryAddress: '',
            memory: m.memory ? m.memory : memory ? memory : null,
            polling: m.polling === true || m.polling === 'true',
            level: 'Metric',
            translations: {},
            savingMode: m.savingMode || 'Other',
            tags: m.tags
          })
        } else {
          metricSubcomponents.push(m)
        }
      })

      data.subcomponents = metricSubcomponents
    }
  }

  if (data.subcomponents && data.subcomponents.length > 0) {
    data.subcomponents
      .filter((s: NodeType) => s.level !== 'Metric')
      .forEach((e: any) => {
        hydrateNodesMeasures(e)
      })
  }
}

export const addChild = (path: string | null, node: any, data: any): void => {
  if (!path) {
    return
  }

  if (data.path === path) {
    if (data.subcomponents) {
      data.subcomponents.push(node)
      return
    } else {
      data.subcomponents = [node]
      return
    }
  }

  if (data.subcomponents && data.subcomponents.length > 0) {
    const ind = data.subcomponents.findIndex((el: any) => {
      return el && el.path === path
    })

    if (ind !== -1) {
      if (data.subcomponents[ind].subcomponents) {
        data.subcomponents[ind].subcomponents.push(node)
        return
      } else {
        data.subcomponents[ind].subcomponents = [node]
        return
      }
    }

    if (data.subcomponents && data.subcomponents.length > 0) {
      data.subcomponents.forEach((e: any) => {
        addChild(path, node, e)
      })
    }
  }
}

export const generateRandomString = (length: number): string => {
  let text = ''
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZZYXWVUTSRQPONMLKJIHGFEDCBAABCDEFGHIJKLMNOPQRSTUVWXYZ'

  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * Math.random() * possible.length))
  }

  return text
}

export const generateRandomAlpha = (length: number): string => {
  let text = ''
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'

  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * Math.random() * possible.length))
  }

  return text
}

export const generateNodeIdSrc = (): string => {
  return (
    'IM' +
    (typeof PLANT_PREFIX === 'string' && PLANT_PREFIX.length === 3 ? PLANT_PREFIX : 'GLO') +
    generateRandomAlpha(9) +
    generateRandomString(2)
  )
}

export const generateNodeId = (): string => {
  let out = ''
  // let nn = 0;

  while (out === '' || usedNodeIds.includes(out)) {
    out = generateNodeIdSrc()
    // nn++
  }

  usedNodeIds.push(out)

  return out
}

export const getAllIds = (data: any) => {
  if (data.level === 'Metric') {
    usedNodeIds.push(data.id)
  } else if (data.data.id) {
    usedNodeIds.push(data.data.id)
  }

  if (data.subcomponents) {
    data.subcomponents.forEach((e: any) => getAllIds(e))
  }
}

export const saveAllIds = (data: any) => {
  usedNodeIds = []

  getAllIds(data)
}

export const cleanCopiedNodes = (data: Partial<NodeType> & { id: string }): void => {
  if (data.path) {
    delete data.path
  }

  if (data.level === 'Metric') {
    data.id = generateNodeId()
  } else if (data.level === 'Component') {
    data.data.id = generateNodeId()
    data.data.pid = ''
    data.data.fpid = ''

    if (data.data.nodeType !== null && data.data.nodeType !== 'i4P_NoNodeType') {
      // metrics from template
      data.subcomponents = []
    } else {
      // component with custom metrics
      if (data.subcomponents && data.subcomponents.length > 0) {
        data.subcomponents.map((metric: any) => {
          const metricId = metric.id.split('_')
          metric.memoryAddress = ''
          metric.id = data.data.id + '_' + metricId[metricId.length - 1]
          metric.label = data.data.id + '_' + metricId[metricId.length - 1]
          return metric
        })
      }
    }
  } else {
    data.data.id = generateNodeId()
    data.data.label = data.data.label + ' - copy'
  }

  if (data.subcomponents && data.subcomponents.length > 0) {
    data.subcomponents = data.subcomponents.filter(
      (e: any) => !(e.level === 'Metric' && (e.type === 'Label' || e.type === 'Type'))
    )

    data.subcomponents.forEach((e: any) => {
      cleanCopiedNodes(e)
    })
  }
}

export const getAllQRCodes = (node: any) => {
  const qrcodes: any = []

  const findQRCodes = (activeNode: NodeType) => {
    if (activeNode && activeNode.hasOwnProperty('data') && activeNode.data.hasQRCode) {
      qrcodes.push([activeNode.data.id, activeNode.data.label, activeNode.level, activeNode.data.pid])
    }

    if (activeNode.hasOwnProperty('subcomponents') && activeNode.subcomponents) {
      activeNode.subcomponents.forEach((e: NodeType | MetricTemplate) => findQRCodes(e as NodeType))
    }
  }

  findQRCodes(node)

  return qrcodes
}
