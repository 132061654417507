import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { connect } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import { YOUTUBE_KEY } from '../../views/DesignerComponent/DesignerComponentPageView'
import { youtubeParser } from '@mv-submodules/inplant-designer-fe/functions/youtube'
import Row from "@mv-submodules/inplant-components-fe/ui/components/Grid/Row";
import Column from "@mv-submodules/inplant-components-fe/ui/components/Grid/Column";
import {Modal} from "@mv-submodules/inplant-components-fe";

export interface VideoData {
  title: null | string
  image: null | { height: number; url: string; width: number }
  description: null | string
}

export interface StateProps {
  data: any
  error: boolean
  lastUpdate: null | number
}

export interface OwnStateProps {
  data: any
  preview: VideoData
  mediaData: any
  videoUrl: undefined | string
  videoTitle: undefined | string
}

export interface OwnProps extends RouteComponentProps<any> {
  addMedia: Function
  closeModal: () => void
  isVisible: boolean
  // t: TranslationFunction
}

export type Props = StateProps & OwnProps & WithTranslation

export const mapStateToProps = (state: any) => ({
  data: state.designer.storage.data,
  error: state.designer.storage.error,
  lastUpdate: state.designer.storage.lastUpdate,
})

class MediaPlayerModal extends React.Component<Props, OwnStateProps> {
  constructor(props: Props) {
    super(props)

    this.state = {
      data: this.props.data || [],
      preview: {
        title: null,
        image: null,
        description: null,
      },
      mediaData: null,
      videoUrl: '',
      videoTitle: '',
    }

    this.confirmSelection = this.confirmSelection.bind(this)
    this.previewMedia = this.previewMedia.bind(this)
    this.setTitle = this.setTitle.bind(this)
    this.handleMediaUrl = this.handleMediaUrl.bind(this)
  }

  public componentDidMount() {
    if (
      this.props.data === [] ||
      !this.props.lastUpdate ||
      this.props.lastUpdate + 10800 < Math.floor(new Date().getTime() / 1000)
    ) {
      console.log('player started') // tslint:disable-line
    }
  }

  public render() {
    return (
      <Modal visible={true}  title={this.props.t('designer.mediaPreview.modal.title')} closeLabel={this.props.t('designer.mediaPreview.modal.cancel')} onClose={this.props.closeModal} additionalFooterButtons={[{
        variant: "primary",
        disabled: (!this.state.videoUrl || !this.state.videoTitle || this.state.videoTitle === ''),
        onClick: this.confirmSelection,
        label: this.props.t('designer.mediaPreview.modal.select')
      }]}>
          <Row>
            <Column md={6} formGroup={true}>
              <div className="input-group">
                <input
                  id="media-url"
                  className="form-control"
                  type="text"
                  name="url"
                  required={true}
                  onBlur={this.previewMedia}
                  onChange={this.handleMediaUrl}
                  value={this.state.videoUrl}
                  placeholder={this.props.t('designer.mediaPreview.modal.placeholder.url')}
                  autoFocus={true}
                />
              </div>
            </Column>
            <Column md={6} formGroup={true}>
              <div className="input-group">
                <input
                  id="media-title"
                  className="form-control"
                  type="text"
                  name="label"
                  required={true}
                  onChange={this.setTitle}
                  value={this.state.videoTitle}
                  placeholder={this.props.t('designer.mediaPreview.modal.placeholder.title')}
                />
              </div>
            </Column>
          </Row>
          {this.state.preview && YOUTUBE_KEY && (
            <Row >
              <Column xs={12}>
                <h4>{this.props.t('designer.mediaPreview.modal.previewTitle')}</h4>
              </Column>
              <Column md={5}>
                <div className={"preview-image"}>
                  {this.state.preview.image && this.state.preview.image.url && (
                    <img src={this.state.preview.image!.url} alt={this.state.preview.title || ''} />
                  )}
                </div>
              </Column>
              <Column md={7}>
                <div className={"preview-meta"}>
                  {this.state.preview.title && (
                    <React.Fragment>
                      <h3>{this.state.preview.title}</h3>
                      <p>{this.state.preview.description}</p>
                    </React.Fragment>
                  )}
                </div>
              </Column>
            </Row>
          )}
      </Modal>
    )
  }

  private confirmSelection(e: React.MouseEvent<HTMLButtonElement>) {
    if (e) {
      e.preventDefault()
    }

    this.props.addMedia(this.state.videoUrl, this.state.videoTitle, this.state.preview)
  }

  private setTitle(e: React.ChangeEvent<HTMLInputElement>) {
    const videoTitle = e.currentTarget.value

    this.setState({
      videoTitle: videoTitle || this.state.preview.title || '',
    })
  }

  private handleMediaUrl(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      videoUrl: e.currentTarget.value,
    })
  }

  private previewMedia(e: React.ChangeEvent<HTMLInputElement>) {
    const videoUrl = e.currentTarget.value

    if (videoUrl) {
      if (YOUTUBE_KEY) {
        fetch(
          'https://www.googleapis.com/youtube/v3/videos?part=snippet&' +
            '' +
            'id=' +
            youtubeParser(videoUrl) +
            '&key=' +
            YOUTUBE_KEY
        )
          .then(response => response.json())
          .then((data: any) => {
            if (data && data.items && data.items.length > 0) {
              const video: any = data.items[0].snippet

              this.setState({
                preview: {
                  title: video.title,
                  image:
                    video.thumbnails.standard ||
                    video.thumbnails.high ||
                    video.thumbnails.medium ||
                    video.thumbnails.default,
                  description: video.description,
                },
                videoUrl,
                videoTitle: this.state.videoTitle || video.title,
              })
            }
          })
          .catch((error: any) => {
            console.log(error) // tslint:disable-line
          })
      } else {
        // no youtube preview
        this.setState({
          preview: {
            title: null,
            image: null,
            description: null,
          },
          videoUrl,
        })
      }
    } else {
      this.setState({
        preview: {
          title: null,
          image: null,
          description: null,
        },
        videoUrl: '',
      })
    }
  }
}

export default withRouter(connect(mapStateToProps)(withTranslation()(MediaPlayerModal)))
