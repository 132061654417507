import { Template } from '../index'
import { ComponentGroup } from './componentGroup'
import { Component } from './component'
import { Metric } from './metric'
import { Section } from './section'

export const SectionGroup: Template = {
  id: 'sectiongroup',
  name: 'SectionGroup',
  version: '0.1.0',
  date: '2019-02-21',
  fields: [
    {
      name: 'label',
      type: 'string',
      label: 'section.label',
      width: 12,
      required: true,
      nodePath: 'data',
      translatable: true,
    },
    {
      name: 'hasQRCode',
      type: 'boolean',
      label: 'section.hasQRCode',
      width: 4,
      nodePath: 'data',
      values: [true]
    },
  ],
  copyable: true,
  allowedSubcomponents: [Section, ComponentGroup, Component, Metric],
}
