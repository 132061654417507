import * as moment from 'moment'

const saveTextAsFile = (data: string, fileName?: string, fileType?: string) => {
  const a = document.createElement('a')
  a.innerHTML = 'Click to download'

  // IE11 & Edge
  if ((navigator as any).msSaveBlob) {
    const textData = new Blob([data], { type: 'text/plain;charset=utf-8;' })
    a.href = '#'
    a.addEventListener('click', () => {
      (navigator as any).msSaveBlob(
        textData,
        (fileName || 'download') + '_' + moment().format('YYYY-MM-DD_HH-mm-ss') + '.' + (fileType || 'txt')
      )
    })
  } else {
    // In FF link must be added to DOM to be clicked
    a.href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(data)
    a.target = '_blank'
    a.download = (fileName || 'download') + '_' + moment().format('YYYY-MM-DD_HH-mm-ss') + '.' + (fileType || 'txt')
  }

  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

export { saveTextAsFile }
