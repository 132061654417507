import { APIStorage } from './index'
import { AnyAction, Dispatch } from 'redux'
import { logoutUser } from '@mv-submodules/inplant-core-fe/auth'

export const isStorageFetching = (bool: boolean): AnyAction => {
  return {
    type: 'DESIGNER_STORAGE_IS_FETCHING',
    fetching: bool,
  }
}

export const fetchStorageSuccess = (dataStructure: object): AnyAction => {
  return {
    type: 'DESIGNER_STORAGE_FETCH_SUCCESS',
    data: dataStructure,
  }
}

export const fetchStorageError = (error: Error): AnyAction => {
  return {
    payload: error,
    type: 'DESIGNER_STORAGE_FETCH_ERROR',
  }
}

export const fetchStorageData = (): ((dispatch: Dispatch<AnyAction>) => Promise<void>) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    dispatch(isStorageFetching(true))

    try {
      const data = await APIStorage().request('/storage/tree')

      return Promise.all([data]).then(values => {
        dispatch(isStorageFetching(false))
        dispatch(fetchStorageSuccess(values[0]))
      })
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }

      dispatch(isStorageFetching(false))
      dispatch(fetchStorageError(new Error(error)))
      throw  error
    }
  }
}

/*****************************************************/

export const isFileFetching = (bool: boolean): AnyAction => {
  return {
    type: 'DESIGNER_FILE_IS_FETCHING',
    fetching: bool,
  }
}

export const fetchFileSuccess = (dataStructure: object): AnyAction => {
  return {
    type: 'DESIGNER_FILE_FETCH_SUCCESS',
    data: dataStructure,
  }
}

export const fetchFileError = (error: Error): AnyAction => {
  return {
    payload: error,
    type: 'DESIGNER_FILE_FETCH_ERROR',
  }
}

export const fetchFileData = (fileKey: string): ((dispatch: Dispatch<AnyAction>) => Promise<void>) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    dispatch(isFileFetching(true))

    try {
      const data = await APIStorage().request('/storage/get/' + encodeURIComponent(fileKey))

      dispatch(isFileFetching(false))
      dispatch(fetchFileSuccess(data))

      return data
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }

      dispatch(isFileFetching(false))
      dispatch(fetchFileError(new Error(error)))

      throw error
    }
  }
}
